<template>
  <div class="footer-body">
    <div class="horizontal-page-margin">
      <div class="row">
        <div class="col-md-2 col-sm-6">
          <div class="heading">{{ policies.heading }}</div>
          <div
            class="link"
            v-for="(label, index) in policies?.subHeading"
            :key="index"
          >
            <a :href="policies?.linkSubHeading?.[index]">{{ label }}</a>
          </div>
        </div>

        <div class="col-md-2 col-sm-6">
          <div class="heading">{{ corporate.heading }}</div>
          <div
            class="link"
            v-for="(label, index) in corporate?.subHeading"
            :key="index"
          >
            <a :href="corporate?.linkSubHeading?.[index]">{{ label }}</a>
          </div>
        </div>

        <div class="col-md-2 col-sm-6">
          <div class="heading">{{ shipping.heading }}</div>
          <div
            class="link"
            v-for="(label, index) in shipping?.subHeading"
            :key="index"
          >
            <a :href="shipping?.linkSubHeading?.[index]">{{ label }}</a>
          </div>
        </div>

        <div class="col-md-2 col-sm-6">
          <div class="heading">{{ help.heading }}</div>
          <div
            class="link"
            v-for="(label, index) in help?.subHeading"
            :key="index"
          >
            <a :href="help?.linkSubHeading?.[index]">{{ label }}</a>
          </div>
        </div>
        <div class="col-md-4 col-sm-12">
          <div class="signup-text">{{ socialMedia?.signupText }}</div>
          <!-- <div> -->
          <!-- <input :placeholder="socialMedia?.signupPlaceholder" /> -->
          <button class="hover-effect-noshadow" @click="signup">
            {{ submitButtonText }}
          </button>
          <!-- </div> -->
          <div class="social">
            <a class="social-link" :href="socialMedia?.facebookUrl">
              <div
                class="social-icon div-icon-image"
                :style="{
                  backgroundImage: `url(${socialMedia?.facebookLogo?.fields?.file?.url})`,
                }"
              ></div>
            </a>
            <a class="social-link" :href="socialMedia?.instagramUrl">
              <div
                class="social-icon div-icon-image"
                :style="{
                  backgroundImage: `url(${socialMedia?.instagramLogo?.fields?.file?.url})`,
                }"
              ></div>
            </a>
            <a class="social-link" :href="socialMedia?.twitterUrl">
              <div
                class="social-icon div-icon-image"
                :style="{
                  backgroundImage: `url(${socialMedia?.twitterLogo?.fields?.file?.url})`,
                }"
              ></div>
            </a>
            <a class="social-link" :href="socialMedia?.youtubeUrl">
              <div
                class="social-icon div-icon-image"
                :style="{
                  backgroundImage: `url(${socialMedia?.youtubeLogo?.fields?.file?.url})`,
                }"
              ></div
            ></a>
            <a class="social-link" :href="socialMedia?.whatsappUrl">
              <div
                class="social-icon div-icon-image"
                :style="{
                  backgroundImage: `url(${socialMedia?.whatsappLogo?.fields?.file?.url})`,
                }"
              ></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import entryId from "@/config/entryid.json";

export default defineComponent({
  name: "AlivFooter",
  inject: ["$contentful"],
  data() {
    return {
      policies: {},
      corporate: {},
      shipping: {},
      help: {},
      socialMedia: {},
      submitButtonText: "Submit",
    };
  },
  created() {
    this.$contentful.getEntry(entryId.footer.policies).then((val) => {
      this.policies = val.fields;
    });
    this.$contentful.getEntry(entryId.footer.corporate).then((val) => {
      this.corporate = val.fields;
    });
    this.$contentful.getEntry(entryId.footer.shipping).then((val) => {
      this.shipping = val.fields;
    });
    this.$contentful.getEntry(entryId.footer.help).then((val) => {
      this.help = val.fields;
    });
    this.$contentful.getEntry(entryId.footer.socialMedia).then((val) => {
      this.socialMedia = val.fields;
    });
  },
  methods: {
    signup() {
      window.location.href = this.socialMedia?.signupUrl;
    },
  },
});
</script>

<style scoped lang="scss">
.footer-body {
  border-top: 16px solid #635890;
  background-color: #0e0e0e;
  padding: $medium-spacing 0;
}

.heading {
  color: #fff;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 10px;
}

.link {
  color: #828282;
  font-size: $font-xxs;
  font-style: normal;
  font-weight: 450;
  line-height: 16px;
  margin-top: 5px;
}

a {
  color: #828282;
  text-decoration: none;
}

.signup-text {
  color: #d2d3e0;
  font-size: $font-xxs;
  font-style: normal;
  font-weight: 450;
  line-height: 16px;
}

input {
  display: inline-block;
  margin-top: 10px;
  border-radius: 4px;
  border: 1px solid #2c2d3b;
  background: #21232d;
  color: #8d8d92;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  padding: 12px 20px;
  margin-right: 10px;
}

button {
  display: inline-block;
  background: #635890;
  font-size: $font-xs;
  font-weight: 450;
  line-height: 18px;
  letter-spacing: 0em;
  color: white;
  border: 1px solid #2c2d3b;
  outline: none;
  border-radius: 100px;
  padding: 7px 20px;
  margin-top: 10px;
}

.social {
  margin-top: 20px;
}

.social-link {
  display: inline-block;
  margin-right: 16px;
}

.social-icon {
  height: 24px;
  width: 24px;
}
</style>
