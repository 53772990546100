<template>
  <div class="product-card hover-effect" @click="cardClicked">
    <div class="card-contents">
      <div
        class="icon div-icon-image"
        :style="{ backgroundImage: `url(${this.iconUrl})` }"
      ></div>
      <div class="heading">{{ title }}</div>
      <div class="description">{{ description }}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import router from "@/router";

export default defineComponent({
  name: "TopBorderFibrCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    iconUrl: {
      type: String,
      default: "",
    },
    minHeight: {
      type: String,
      default: "214px",
    },
    routeName: {
      type: String,
      default: "",
    },
  },
  methods: {
    cardClicked(e) {
      e.stopPropagation();
      router.push({ name: this.routeName });
    },
  },
});
</script>

<style scoped lang="scss">
.product-card {
  min-height: v-bind("minHeight");
  height: 100%;
  width: 100%;
  background-color: white;
  display: table;
  position: relative;
  border-radius: $border-radius;
  border: 1px solid #f2f2f2;
  border-top: 8px solid #635890;
  box-shadow:
    0px 7.9253997802734375px 10.053936004638672px 0px rgba(0, 0, 0, 0.05),
    0px 21.912715911865234px 27.7978458404541px 0px rgba(0, 0, 0, 0.03),
    0px 52.75737762451172px 66.92649841308594px 0px rgba(0, 0, 0, 0.03),
    0px 175px 222px 0px rgba(0, 0, 0, 0.02);
}

.card-contents {
  display: table-cell;
  vertical-align: middle;
  padding: 40px;
}

.heading {
  font-size: $font-m;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #000;
  text-align: center;
}

.description {
  font-size: $font-s;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  color: #4f4f4f;
  max-width: 432px;
  text-align: center;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
}

.icon {
  text-align: center;
  margin-bottom: 12px;
  height: 40px;
  width: 40px;
  margin-left: auto;
  margin-right: auto;
}
</style>
