<template>
  <div class="section-margin">
    <div
      v-for="(testimonial, index) in testimonials.filter((t) => t.isHeader)"
      :key="index"
      :style="{
        border: '1px solid #cbd5e1',
        padding: '.1rem 6rem 2rem 6rem',
        margin: '2rem 0 2rem 0',
        position: 'relative',
        borderRadius: '1rem',
        backgroundColor: 'white',
        boxShadow: '1px 1px 10px #cbd5e1',
      }"
      style="@media (max-width: 900px) {padding: 0.1rem 0.2rem 2rem 0.2rem}"
    >
      <div
        class="testimonial-rating"
        :style="{
          transform: 'translateY(-30px)',
        }"
      >
        <img
          :src="testimonial.avatarUrl"
          alt="avatar"
          :style="{
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            objectFit: 'cover',
            margin: '0 5px',
            border: '1px solid #cbd5ff',
          }"
        />
      </div>
      <h4 class="text-md-center">{{ testimonial.title }}</h4>
      <p class="text-sm-center" style="color: gray">
        <em>{{ testimonial.occupation }}</em>
      </p>
      <p class="position-relative text-sm-center">
        {{ testimonial.insights }}
        <img
          src="/quote.png"
          alt="quote"
          :style="{
            width: '80px',
            height: '80px',
            position: 'absolute',
            top: '-40px',
            left: '-80px',
            transform: 'translateX(50%)',
            transformOrigin: 'right',
            opacity: '0.2',
            transition: 'opacity 0.3s ease',
          }"
        />
      </p>
      <div class="testimonial-rating">
        <img
          v-for="star in testimonial.rating"
          :key="star"
          :src="getStarImagePath()"
          alt="Star"
          class="star-image"
        />
      </div>
    </div>
    <div class="carousel-container">
      <Carousel
        ref="testimonialCarousel"
        :items-to-show="3"
        :wrap-around="true"
        :paginationEnabled="false"
        :autoplay="false"
        :breakpoints="{
          '1200': { itemsToShow: 3 },
          '768': { itemsToShow: 1 },
          '0': { itemsToShow: 1 },
        }"
        @slideChange="handleSlideChange"
      >
        <Slide v-for="(testimonial, index) in testimonials" :key="index">
          <div class="testimonial-slide text-center">
            <h4 class="testimonial-title">{{ testimonial.title }}</h4>
            <p class="testimonial-occupation">
              <em>{{ testimonial.occupation }}</em>
            </p>
            <p class="testimonial-insights position-relative">
              {{ testimonial.insights }}
              <img
                src="/quote.png"
                alt="quote"
                :style="{
                  width: '80px',
                  height: '80px',
                  position: 'absolute',
                  top: '-40px',
                  left: '-40px',
                  transform: 'translateX(50%)',
                  transformOrigin: 'right',
                  opacity: '0.2',
                  transition: 'opacity 0.3s ease',
                }"
              />
            </p>

            <div class="testimonial-rating">
              <img
                v-for="star in testimonial.rating"
                :key="star"
                :src="getStarImagePath()"
                alt="Star"
                class="star-image"
              />
            </div>
          </div>
        </Slide>
      </Carousel>

      <button class="carousel-nav-btn prev" @click="prevSlide">←</button>
      <button class="carousel-nav-btn next" @click="nextSlide">→</button>
    </div>

    <div class="pagination">
      <span
        v-for="(testimonial, index) in testimonials"
        :key="index"
        class="dot"
        :class="{ active: index === currentSlideIndex }"
        @click="goToSlide(index)"
      ></span>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue3-carousel";

export default {
  name: "TestimonialCarousel",
  components: {
    Carousel,
    Slide,
  },
  props: {
    testimonials: {
      type: Array,
      required: true,
    },
    starImagePath: {
      type: String,
      default: "/star-image.png",
    },
  },
  data() {
    return {
      currentSlideIndex: 0,
    };
  },
  methods: {
    prevSlide() {
      this.$refs.testimonialCarousel.prev();
      const newIndex =
        this.currentSlideIndex === 0
          ? this.testimonials.length - 1
          : this.currentSlideIndex - 1;
      this.handleSlideChange(newIndex);
    },
    nextSlide() {
      this.$refs.testimonialCarousel.next();
      const newIndex =
        this.currentSlideIndex === this.testimonials.length - 1
          ? 0
          : this.currentSlideIndex + 1;
      this.handleSlideChange(newIndex);
    },
    goToSlide(index) {
      this.$refs.testimonialCarousel.slideTo(index);
      this.currentSlideIndex = index;
    },
    handleSlideChange(index) {
      this.currentSlideIndex = index;
    },
    getStarImagePath() {
      return this.starImagePath;
    },
  },
};
</script>

<style scoped>
.testimonial-slide {
  padding: 20px;
  background-color: #625691;
  border-radius: 16px;
  margin: 10px;
}

.testimonial-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto 20px;
}

.testimonial-title {
  font-weight: bold;
  font-size: 1.2rem;
  color: #fff;
}

.testimonial-occupation {
  color: #ddd;
  margin-bottom: 15px;
  font-size: 0.8rem;
}

.testimonial-insights {
  font-style: italic;
  margin-bottom: 15px;
  color: #f0f0f0;
  font-size: 0.8rem;
}

.testimonial-rating {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.star-image {
  width: 25px;
  height: 25px;
}

.carousel-container {
  position: relative;
}

.carousel-nav-btn {
  background-color: #f9f6f6;
  color: black;
  border: 1px solid #cbd5e1;
  padding: 6px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  border-radius: 50%;
  box-shadow: 1px 1px 3px #fff3;
  width: 40px;
  height: 40px;
}

.carousel-nav-btn.prev {
  left: -50px;
  @media (max-width: 1200px) {
    display: none;
  }
}

.carousel-nav-btn.next {
  right: -50px;
  @media (max-width: 1200px) {
    display: none;
  }
}

@media (max-width: 768px) {
  .carousel-nav-btn {
    width: 30px;
    height: 30px;
  }

  .carousel-nav-btn.prev {
    left: 10px;
  }

  .carousel-nav-btn.next {
    right: 10px;
  }
}

.pagination {
  text-align: center;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #9fa7ad;
  border-radius: 50%;
  cursor: pointer;
}

.dot.active {
  background-color: #635890;
}
</style>
