<template>
  <div>
    <div class="heading">{{ title }}</div>
    <RichText
      v-if="isRichText"
      class="content-dark"
      :document="descriptionRt"
    ></RichText>
    <div v-else class="content-dark">{{ description }}</div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import RichText from "@/components/common/RichText.vue";

export default defineComponent({
  name: "FeatureDescription",
  components: {
    RichText,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    descriptionRt: {
      type: Object,
      default: () => {},
    },
    isRichText: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped lang="scss">
.heading {
  font-style: normal;
  font-weight: 700;
  font-size: $font-l;
  line-height: 51px;
  text-align: center;
  color: #000000;
  margin-bottom: 16px;
}

.content-dark {
  text-align: center;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}
</style>
