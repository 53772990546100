<template>
  <div
    class="horizontal-page-margin vertical-page-margin section-margin-xlarge"
  >
    <ImageWithText
      :imageUrl="fields?.image?.fields?.file?.url"
      :leftAlignImage="false"
    >
      <div>
        <div class="main-heading">{{ fields?.heading }}</div>
        <RichText class="main-copy" :document="fields?.description"></RichText>
      </div>
    </ImageWithText>

    <div class="section-heading section-margin-small">
      {{ fields?.browseFaqSectionHeading }}
    </div>
    <AlivTabs class="tabs">
      <AlivTab
        v-for="(category, categoryName) in faqCategories"
        :key="categoryName"
        :title="categoryName"
        :showBackground="false"
      >
        <div class="row">
          <div class="col-lg-4" v-for="(topic, index) in category" :key="index">
            <div
              class="topic-image div-background-image hover-effect"
              @click="topicClicked(topic?.id)"
              :style="{
                backgroundImage: `url(${topic?.image?.fields?.file?.url})`,
              }"
            ></div>

            <div class="topic-title">{{ topic?.faqCategoryTitle }}</div>
            <div class="topic-copy">{{ topic?.description }}</div>
            <!-- <video
              class="topic-image"
              :src="topic?.video?.fields?.file?.url"
              controls
              v-if="topic?.video"
            ></video> -->
          </div>
        </div>
      </AlivTab>
    </AlivTabs>
  </div>
</template>

<script>
import AlivTabs from "@/components/common/AlivTabs.vue";
import AlivTab from "@/components/common/AlivTab.vue";
import ImageWithText from "@/components/common/ImageWithText.vue";
import RichText from "@/components/common/RichText.vue";
import entryId from "@/config/entryid.json";
import router from "@/router";

export default {
  name: "FibrFAQ",
  inject: ["$contentful"],
  components: { ImageWithText, RichText, AlivTabs, AlivTab },
  data() {
    return {
      fields: {},
      faqCategories: {},
    };
  },
  created() {
    this.$contentful.getEntry(entryId.faq.main).then((val) => {
      this.fields = val.fields;
      val.fields?.faqSectionCategories.forEach((category) => {
        this.faqCategories[category] = [];
      });

      val.fields?.faqCategories.forEach((category) => {
        category.fields.id = category.sys.id;
        this.faqCategories[category.fields.faqCategorySection].push(
          category.fields
        );
      });
    });
  },
  methods: {
    topicClicked(id) {
      router.push({
        name: "fibr-faq-topic",
        params: { topic: id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.main-heading {
  color: #000000;
  font-size: $font-l;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
}

.main-copy {
  color: #828282;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  margin-top: 5px;
}

.section-heading {
  color: v#000;
  font-size: $font-m;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.tabs {
  margin-top: 30px;
}

.topic-image {
  width: 100%;
  height: 230px;
  border-radius: $border-radius;
}

.topic-title {
  color: #000;
  font-size: $font-s;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 24px;
}

.topic-copy {
  color: #4f4f4f;
  font-size: $font-s;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  margin-top: 5px;
}
</style>
