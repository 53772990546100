import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./plugins/store";
import { createClient } from "./plugins/contentful.js";
import "./assets/stylesheets/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";

const app = createApp(App);
const contentfulClient = createClient();

app.use(router);
app.use(store);

store.commit("loadBundles");
app.provide("$contentful", contentfulClient);

app.mount("#app");
