<template>
  <div class="sports-add-on-container">
    <!-- Logo Section -->
    <div class="logo-section">
      <img
        src="https://images.ctfassets.net/u7e5kg3vu9je/1r47EyDHiXBmlojye7r0mz/9e79cfe88b788f0769f45f6f2fb78270/alivFibrLogo_2.png"
        alt="Logo"
        class="logo"
      />
    </div>

    <!-- Form Section -->
    <div class="form-section">
      <div v-if="step < 5" class="form-header">
        <h2>Account Upgrade Sign Up</h2>
        <p class="subtitle">
          Enhance your experience with exclusive add-ons tailored for you!
        </p>
      </div>

      <form v-if="step < 5" @submit.prevent="handleSubmit">
        <!-- Step 1: User Information -->
        <div v-if="step === 1">
          <div class="form-group">
            <label for="name">Name</label>
            <input
              type="text"
              id="name"
              v-model="form.name"
              placeholder="Enter your first and last name"
              required
            />
          </div>
          <div class="form-group">
            <label for="accountNumber">Account Number</label>
            <input
              type="text"
              id="accountNumber"
              v-model="form.accountNumber"
              placeholder="1234-5678-9012"
              required
            />
          </div>
          <div class="form-group">
            <label for="phone">Phone Number</label>
            <input
              type="tel"
              id="phone"
              v-model="form.phone"
              placeholder="Enter your phone number"
              required
            />
          </div>
          <div class="button-group">
            <button
              type="button"
              class="btn-primary"
              @click="nextStep"
              :disabled="!isStep1Complete"
            >
              Next
            </button>
          </div>
        </div>

<!--        &lt;!&ndash; Step 2: Add-on Selection &ndash;&gt;-->
<!--        <div v-if="step === 2">-->
<!--          <div class="form-group">-->
<!--            <label>Select Add-ons</label>-->
<!--            <div-->
<!--              v-for="(option, index) in addOnOptions"-->
<!--              :key="index"-->
<!--              :class="[-->
<!--                'option-button',-->
<!--                { selected: form.addOnPackage === option },-->
<!--              ]"-->
<!--              @click="form.addOnPackage = option"-->
<!--            >-->
<!--              {{ option }}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="button-group">-->
<!--            <button type="button" class="btn-secondary" @click="prevStep">-->
<!--              Back-->
<!--            </button>-->
<!--            <button-->
<!--              type="button"-->
<!--              class="btn-primary"-->
<!--              @click="nextStep"-->
<!--              :disabled="!form.addOnPackage"-->
<!--            >-->
<!--              Next-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->

        <!-- Step 3: Dropdown for Additional Options -->
        <div v-if="step === 2">
          <div class="form-group">
            <label for="addOnOption">Select an Additional Option</label>
            <select
              id="addOnOption"
              v-model="form.addOnDetail"
              required
              class="dropdown"
            >
              <option disabled value="">Select an option</option>
              <option
                v-for="(detail, index) in addOnDetails"
                :key="index"
                :value="detail"
              >
                {{ detail }}
              </option>
            </select>
          </div>
          <div class="button-group">
            <button type="button" class="btn-secondary" @click="prevStep">
              Back
            </button>
            <button
              type="button"
              class="btn-primary"
              @click="nextStep"
              :disabled="!form.addOnDetail"
            >
              Next
            </button>
          </div>
        </div>

        <!-- Step 4: Review Your Information -->
        <div v-if="step === 3">
          <h3>Review Your Information</h3>
          <div class="review-item">
            <span class="label">Name:</span> {{ form.name }}
          </div>
          <div class="review-item">
            <span class="label">Account Number:</span> {{ form.accountNumber }}
          </div>
          <div class="review-item">
            <span class="label">Phone Number:</span> {{ form.phone }}
          </div>
          <div class="review-item">
            <span class="label">Selected Add-on:</span> {{ form.addOnPackage }}
          </div>
          <div class="review-item">
            <span class="label">Additional Option:</span> {{ form.addOnDetail }}
          </div>
          <div class="button-group">
            <button type="button" class="btn-secondary" @click="prevStep">
              Back
            </button>
            <button type="submit" class="btn-primary">
              Confirm and Submit
            </button>
          </div>
        </div>
      </form>

      <!-- Step 5: Confirmation -->
      <div v-if="step === 4" class="confirmation-screen">
        <h2>Thank You!</h2>
        <p>
          We’ve received your information and will contact you soon to complete
          the process.
        </p>
        <p>
          If you have any questions, feel free to reach out to our support team.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AddOnForm",
  data() {
    return {
      step: 1,
      form: {
        name: "",
        accountNumber: "",
        phone: "",
        addOnPackage: "",
        addOnDetail: "",
      },
      addOnOptions: [
        "Movies",
        "Kids",
        "International",
        "Music",
        "Lifestyle Extra",
        "News",
        "Science",
      ],
      addOnDetails: [
        "Add phone service",
        "Port phone number",
        "Upgrade speed",
        "Other",
      ],
    };
  },
  computed: {
    isStep1Complete() {
      return (
        this.form.name.trim() !== "" &&
        this.form.accountNumber.trim() !== "" &&
        this.form.phone.trim() !== ""
      );
    },
  },
  methods: {
    nextStep() {
      this.step++;
    },
    prevStep() {
      this.step--;
    },
    async handleSubmit() {
      await axios.post("https://email-server-swart.vercel.app/api/v89/email", {
        subject: "Add-ons Signup",
        message: this.getAccountDetailsTemplate(this.form),
      });
      this.step = 5; // Move to confirmation screen
    },
    getAccountDetailsTemplate(form) {
      return `
      <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Add-ons Signup</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                background-color: #f4f4f9;
                color: #333;
                margin: 0;
                padding: 0;
              }
              .email-container {
                max-width: 600px;
                margin: 20px auto;
                background: #ffffff;
                border-radius: 10px;
                box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
                overflow: hidden;
                border: 1px solid #ddd;
              }
              .header {
                background-color: #4a90e2;
                color: #ffffff;
                text-align: center;
                padding: 20px;
              }
              .header h2 {
                margin: 0;
                font-size: 24px;
              }
              .content {
                padding: 20px;
              }
              .detail {
                margin-bottom: 15px;
              }
              .detail span.label {
                display: inline-block;
                font-weight: bold;
                color: #4a90e2;
                width: 150px;
              }
              .footer {
                text-align: center;
                padding: 10px;
                background: #f4f4f9;
                font-size: 12px;
                color: #666;
              }
            </style>
          </head>
          <body>
            <div class="email-container">
              <!-- Header Section -->
              <div class="header">
                <h2>Add-ons Signup</h2>
              </div>
              <!-- Content Section -->
              <div class="content">
                <div class="detail">
                  <span class="label">Name:</span> ${form.name}
                </div>
                <div class="detail">
                  <span class="label">Account Number:</span> ${form.accountNumber}
                </div>
                <div class="detail">
                  <span class="label">Phone Number:</span> ${form.phone}
                </div>
                <div class="detail">
                  <span class="label">Selected Add-on:</span> ${form.addOnPackage}
                </div>
                <div class="detail">
                  <span class="label">Additional Option:</span> ${form.addOnDetail}
                </div>
              </div>
              <!-- Footer Section -->
              <div class="footer">
                <p>This is an automated message. Please do not reply.</p>
                <p>&copy; 2024 Your Company Name</p>
              </div>
            </div>
          </body>
          </html>
      `;
    },
  },
};
</script>

<style scoped>
/* General Layout */
.button-group {
  display: flex;
  justify-content: space-between;
  gap: 1rem; /* Adds space between buttons */
}
.sports-add-on-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 2em;
  background: #f9fafc;
  font-family: "Inter", sans-serif;
}

.logo-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1.45);
}

.logo {
  max-width: 30rem;
  height: auto;
}

.form-section {
  flex: 2;
  width: 100%;
  max-width: 600px;
  background: #ffffff;
  border-radius: 15px;
  padding: 2em;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.form-header h2 {
  font-size: 1.8em;
  color: #4b5563;
  margin-bottom: 0.5em;
  text-align: center;
}

.subtitle {
  font-size: 1em;
  color: #6b7280;
  margin-bottom: 1.5em;
  text-align: center;
}

.form-group {
  margin-bottom: 1.5em;
}

label {
  font-weight: 600;
  color: #374151;
  display: block;
  margin-bottom: 0.5em;
}

input,
select {
  width: 100%;
  padding: 0.75em;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 1em;
  color: #4b5563;
  outline: none;
  transition: border-color 0.3s ease;
}

input:focus,
select:focus {
  border-color: #7c3aed;
  box-shadow: 0 0 0 3px rgba(124, 58, 237, 0.2);
}

.option-button {
  display: inline-block;
  width: calc(50% - 1rem);
  padding: 0.75em;
  margin: 0.5em 0.5em 0;
  text-align: center;
  font-size: 1em;
  color: #4b5563;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.option-button:hover {
  border-color: #7c3aed;
  color: #7c3aed;
}

.option-button.selected {
  background: #7c3aed;
  color: #ffffff;
  border-color: #7c3aed;
}

button {
  padding: 0.8em 1.5em;
  font-size: 1em;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: #7c3aed;
  color: white;
}

.btn-primary:hover {
  background-color: #6d28d9;
}

.btn-secondary {
  background-color: #e5e7eb;
  color: #374151;
}

.btn-secondary:hover {
  background-color: #d1d5db;
}

button:disabled {
  background-color: #e5e7eb;
  cursor: not-allowed;
}

.review-item {
  margin-bottom: 1em;
  font-size: 1em;
  color: #374151;
}

.review-item .label {
  font-weight: bold;
  color: #4a90e2;
}

.confirmation-screen {
  text-align: center;
  color: #4b5563;
}

.confirmation-screen h2 {
  font-size: 1.8em;
  margin-bottom: 1em;
}

.confirmation-screen p {
  font-size: 1em;
  color: #6b7280;
}
.sports-add-on-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2em;
  background: #f9fafc;
  font-family: "Inter", sans-serif;
}

.logo-section {
  margin-bottom: 2em;
}

.logo {
  max-width: 200px;
  height: auto;
}

.form-section {
  width: 100%;
  max-width: 600px;
  background: #ffffff;
  border-radius: 15px;
  padding: 2em;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.form-header h2 {
  font-size: 1.8em;
  color: #4b5563;
  margin-bottom: 0.5em;
  text-align: center;
}

.subtitle {
  font-size: 1em;
  color: #6b7280;
  margin-bottom: 1.5em;
  text-align: center;
}

.form-group {
  margin-bottom: 1.5em;
}

label {
  font-weight: 600;
  color: #374151;
  display: block;
  margin-bottom: 0.5em;
}

input,
select {
  width: 100%;
  padding: 0.75em;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 1em;
  color: #4b5563;
  outline: none;
  transition: border-color 0.3s ease;
}

input:focus,
select:focus {
  border-color: #7c3aed;
  box-shadow: 0 0 0 3px rgba(124, 58, 237, 0.2);
}

.option-button {
  display: inline-block;
  width: calc(50% - 1rem);
  padding: 0.75em;
  margin: 0.5em 0.5em 0;
  text-align: center;
  font-size: 1em;
  color: #4b5563;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.option-button:hover {
  border-color: #7c3aed;
  color: #7c3aed;
}

.option-button.selected {
  background: #7c3aed;
  color: #ffffff;
  border-color: #7c3aed;
}

button {
  padding: 0.8em 1.5em;
  font-size: 1em;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: #7c3aed;
  color: white;
}

.btn-primary:hover {
  background-color: #6d28d9;
}

.btn-secondary {
  background-color: #e5e7eb;
  color: #374151;
}

.btn-secondary:hover {
  background-color: #d1d5db;
}

button:disabled {
  background-color: #e5e7eb;
  cursor: not-allowed;
}

.button-group {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.review-item {
  margin-bottom: 1em;
  font-size: 1em;
  color: #374151;
}

.review-item .label {
  font-weight: bold;
  color: #4a90e2;
}

.confirmation-screen {
  text-align: center;
  color: #4b5563;
}

.confirmation-screen h2 {
  font-size: 1.8em;
  margin-bottom: 1em;
}

.confirmation-screen p {
  font-size: 1em;
  color: #6b7280;
}

/* Responsive Design */
@media (max-width: 768px) {
  .form-section {
    padding: 1.5em;
  }
  .button-group {
    flex-direction: column;
    gap: 1rem;
  }
  .option-button {
    width: 100%;
  }
}
</style>
