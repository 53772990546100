<template>
  <div>
    <div v-if="leftAlignImage || isSmallScreen" class="row">
      <div class="col-lg-6">
        <video v-if="isVideo" class="image" controls :src="imageUrl"></video>
        <div
          v-else
          class="image div-background-image"
          :style="{ backgroundImage: `url(${imageUrl})`, height: '400px' }"
        ></div>
      </div>
      <div class="col-lg-6">
        <div class="text-content right-text">
          <slot></slot>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-lg-6">
        <div class="text-content">
          <slot></slot>
        </div>
      </div>
      <div class="col-lg-6">
        <video v-if="isVideo" class="image" controls :src="imageUrl"></video>
        <div
          v-else
          class="image div-background-image"
          :style="{ backgroundImage: `url(${imageUrl})` }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ImageWithText",
  props: {
    imageUrl: {
      type: String,
      default: "",
    },
    leftAlignImage: {
      type: Boolean,
      default: true,
    },
    isVideo: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: String,
      default: "300px",
    },
  },
  computed: {
    isSmallScreen() {
      return window.innerWidth < 992;
    },
  },
});
</script>

<style scoped lang="scss">
video {
  width: 100%;
}

.image {
  min-height: v-bind("minHeight");
  height: 100%;
  border-radius: $border-radius;
}

.text-content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 992px) {
  .right-text {
    width: 100%;
    padding-left: 100px;
    padding-right: 0;
    float: right;
  }
}
</style>
