<template>
  <FibrProductBanner
    :title="banner.title"
    :description="banner.description"
    :imageUrl="banner.imageUrl"
  ></FibrProductBanner>
  <div class="horizontal-page-margin vertical-page-margin">
    <div class="section-margin">
      <div class="row">
        <div v-for="feature in features" :key="feature.id" class="col-md-4">
          <FeatureDescription
            :title="feature.title"
            :description="feature.description"
          ></FeatureDescription>
        </div>
      </div>
    </div>
    <SecondaryFeature
      class="section-margin"
      :title="secondaryFeature.title"
      :description="secondaryFeature.description"
      :imageUrl="secondaryFeature.imageUrl"
    >
    </SecondaryFeature>
    <FeatureDescription
      class="section-margin"
      :title="speed.title"
      :description="speed.description"
    >
    </FeatureDescription>
    <div class="available-text">
      Aliv Fibr is available only for New Providence customers.<br>
      View service availability in your area – explore the map <a href="/map.pdf" target="_blank">here</a>.
    </div>
    <div
      class="justify-content-center align-items-start gap-4 px-auto my-4"
      :style="{
        display: 'flex',
        flexWrap: 'wrap',
        transition: 'height 800ms ease-in-out',
      }"
    >
      <div
        :key="bundle.name"
        :class="{ 'bundle-card-expanded': bundle.isOpen }"
        class="px-3 shadow-lg py-3 rounded-3 bundle-card"
      >
        <h4>{{ bundle.name.split("- ")[1] }}</h4>
        <p>{{ bundle.description }}</p>
        <p
          :style="{ color: '#635890', fontSize: '30px', marginBottom: '0' }"
          class="fw-bolder"
        >
          {{ bundle.price }}
        </p>
        <span
          :style="{ color: '#635890', marginBottom: '8px', fontSize: '15px' }"
          >{{ bundle.vat }}</span
        ><br />
        <button
          @click="() => cardClicked(bundle)"
          type="button"
          :style="{
            marginBottom: '9px',
            marginTop: '9px',
            backgroundColor: '#635890',
            border: 'none',
            color: '#fff',
            padding: '8px 0px',
            borderRadius: '15px',
            fontWeight: '700',
            width: '100%',
          }"
        >
          Get Started
        </button>
        <div v-show="bundle.isOpen" class="bundle-details">
          <BundleDetailsLong
            class="details"
            :bundleId="bundle.id"
          ></BundleDetailsLong>
        </div>
        <button
          @click="toggleDetails(bundle)"
          type="button"
          class="toggle-button"
        >
          {{ bundle.isOpen ? "See less" : "View details" }} <br />
          <img
            class="view-details-arrow"
            :class="{ 'view-details-arrow-open': bundle.isOpen }"
            :src="require('@/assets/icons/purple_arrow.svg')"
          />
        </button>
      </div>
    </div>
    <div
      class="flex link-style my-5"
      style="
        width: 100% !important;
        justify-content: center;
        align-items: center;
        display: flex;
      "
    >
      <a
        :href="viewAll.description"
        class="text-white py-2 px-4 rounded-4 text-decoration-none"
        style="background-color: #635890; font-weight: 900"
        >{{ viewAll.title }}</a
      >
    </div>
    <CheckAliv class="section-margin"></CheckAliv>
  </div>
</template>

<script>
import FibrProductBanner from "@/components/common/FibrProductBanner.vue";
import FeatureDescription from "@/components/common/FeatureDescription.vue";
import SecondaryFeature from "@/components/fibr-products/SecondaryFeature.vue";
// import FibrTalkDetailsCard from "@/components/fibr-products/FibrTalkDetailsCard.vue";
import entryId from "@/config/entryid.json";
import { getEntryFromArray } from "@/util/contentUtil.js";
import CheckAliv from "@/components/common/CheckAliv.vue";
import router from "@/router";
import BundleDetailsLong from "@/components/common/BundleDetailsLong.vue";

export default {
  name: "FibrPhone",
  inject: ["$contentful"],
  components: {
    FibrProductBanner,
    FeatureDescription,
    SecondaryFeature,
    BundleDetailsLong,
    CheckAliv,
  },
  data() {
    return {
      banner: {
        title: "",
        description: "",
        imageUrl: "",
      },
      features: [],
      secondaryFeature: {
        title: "",
        description: "",
        imageUrl: "",
      },
      speed: {
        title: "",
        description: "",
      },
      bundle: {
        name: "",
        description: "",
        imageUrl: "",
        details: [],
        vat: "",
        price: "",
        isOpen: false,
        id: "",
      },
    };
  },
  created() {
    this.$contentful.getEntry(entryId.fibrHomePhone.fibrTalk).then((val) => {
      this.bundle = {
        name: val.fields.bundleName,
        description: val.fields.description.content[0].content[0].value,
        price: val.fields.bundles[0].fields.price,
        vat: val.fields.bundles[0].fields.vat,
        details: val.fields.bundleDetails[0].fields.benefits,
        id: val.sys.id,
        isOpen: false,
      };
      console.log(this.bundle);
    });
    this.$contentful
      .getEntry(entryId.fibrHomePhone.fibrHomePhoneContent)
      .then((val) => {
        const fields = val.fields.body;
        const bannerInfo = getEntryFromArray(
          fields,
          entryId.fibrHomePhone.fibrHomePhoneUnlimitedCalls
        );
        this.banner.title = bannerInfo.title;
        this.banner.description = bannerInfo.description;
        this.banner.imageUrl = bannerInfo.backgroundImage?.fields?.file?.url;
        const featureInfo1 = getEntryFromArray(
          fields,
          entryId.fibrHomePhone.callingFeatures
        );
        const featureInfo2 = getEntryFromArray(
          fields,
          entryId.fibrHomePhone.unlimitedCalls
        );
        const featureInfo3 = getEntryFromArray(
          fields,
          entryId.fibrHomePhone.freeVirtualNumber
        );

        this.features.push({
          id: "1",
          title: featureInfo1.title,
          description: featureInfo1.description,
        });
        this.features.push({
          id: "2",
          title: featureInfo2.title,
          description: featureInfo2.description,
        });
        this.features.push({
          id: "3",
          title: featureInfo3.title,
          description: featureInfo3.description,
        });
        this.viewAll = {
          title: fields[6].fields.title,
          description: fields[6].fields.description,
        };
        const secondaryFeatureInfo = getEntryFromArray(
          fields,
          entryId.fibrHomePhone.longDistanceCalling
        );
        this.secondaryFeature.title = secondaryFeatureInfo.title;
        this.secondaryFeature.description = secondaryFeatureInfo.description;
        this.secondaryFeature.imageUrl =
          secondaryFeatureInfo.backgroundImage?.fields?.file?.url;

        const speedInfo = getEntryFromArray(
          fields,
          entryId.fibrHomePhone.fibrTalkDetails
        );
        this.speed.title = speedInfo.title;
        this.speed.description = speedInfo.description;
      });
  },
  methods: {
    cardClicked(bundle) {
      router.push({
        name: "bundle-details",
        params: { bundle: encodeURI(bundle.name) },
      });
    },
    toggleDetails(bundle) {
      bundle.isOpen = !bundle.isOpen;
    },
  },
};
</script>

<style scoped lang="scss">
.bundle-card {
  height: auto; /* Set to auto to accommodate content */
  border: 1px solid #e0e0e0;
  border-radius: $border-radius;
  display: block;
  position: relative;
  transition: height 800ms ease-in-out;
  overflow: hidden;
  padding-left: 32px;
  padding-right: 32px;
  text-align: center;
  width: 30%;
  &-expanded {
    height: auto;
    transition: height 800ms ease-in-out;
  }
}

.bundle-details {
  margin-top: 30px;
  margin-bottom: 40px;
}

.toggle-button {
  color: #635890;
  border: none;
  border-radius: 16px;
  background-color: white;
  width: 100%;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.view-details-arrow {
  transform: rotate(180deg);
  margin-left: auto;
  margin-right: auto;
  display: block;
  transition: transform 400ms;

  &-open {
    transform: rotate(0deg);
    transition: transform 400ms;
  }
}

.available-text {
  color: #4f4f4f;
  text-align: center;
  font-size: $font-s;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.available-text a {
  color: black; /* Link color in black */
  font-weight: bold;
  text-decoration: underline;
}
.available-text a:hover {
  color: gray; /* Optional hover effect */
}
</style>
