<template>
  <div class="row px-3 py-lg-2">
    <div class="col-12 page-content-wrapper">
      <h1 class="page-title my-5">Complaints Procedure</h1>

      <p>
        Cable Bahamas Ltd&nbsp;prides itself as being a company built on
        excellent customer service; however, we acknowledge that sometimes
        things do go wrong. If, for any reason, you are&nbsp;dissatisfied with
        our services, you have a right to complain to us. This&nbsp;
        <em>Complaints Handling Procedure</em>&nbsp;gives clear and to the point
        information about what you can do if you are dissatisfied. It tells you:
      </p>

      <ul>
        <li>How you can complain;</li>
        <li>What we’ll do and when we’ll do it; and</li>
        <li>What you can do if you are still dissatisfied.</li>
      </ul>

      <p>
        Under this procedure, a “complaint” means an expression of
        dissatisfaction made by a customer to us related to either:
      </p>

      <ul>
        <li>How we provide services to you; or</li>
        <li>This complaints handling procedure itself; and</li>
      </ul>

      <p>
        There is an expectation that a response or resolution is forthcoming.
      </p>

      <p>
        Complaints do not include general requests for information, a service
        request, a fault report, and frivolous or vexatious calls.
      </p>

      <p>
        Hard copies of this procedure and our complaints forms are available
        upon request free of charge at our main office located on Robinson Road,
        Nassau, or at any of our branch offices on the Family Islands.
      </p>

      <p>
        If for some reason you are not able to complete a Complaint Form
        yourself, please contact our Customer Care Department at 601-2200
        (Nassau) or 602-8800 (Freeport), and a member of our friendly staff will
        be happy to assist you.
      </p>

      <p>
        To ensure efficient and effective processing of complaints, CBL will
        periodically review its procedures and notify its customers of any
        significant changes to this Complaints Handling Procedure after first
        obtaining URCA’s approval of these changes.
      </p>

      <p>
        If you are disabled, physically challenged, or incapacitated in any way
        and wish to appoint a representative to lodge your complaint on your
        behalf, we will be happy to work with you, provided proper permission
        and identification of your appointed person is provided to us.
      </p>

      <h4>How you can complain</h4>

      <p>
        You must lodge your complaint with us within sixty (60) business days
        after the incident. To lodge a complaint, simply complete our Complaint
        Form and submit it to us. Our Complaint Forms are available on our
        website or at any of our offices. Along with your completed Complaint
        Form, you may be required to submit supporting information and/or
        documentation related to the complaint, including but not limited to
        letters, contracts, agreements, e-mails, or any other related evidence.
        All documentation submitted will be retained by us for a minimum of 12
        months. If you are hearing or sight impaired, one of our Customer Care
        Representatives will be happy to assist you in completing the form and
        explain this procedure to you. The Complaint Form may be submitted to us
        in any of the following ways:
      </p>

      <h2>Send us your complaint:</h2>

      <iframe
        src="https://forms.zohopublic.eu/cablebahamas/form/ALIVFibrCustomerComplaintsv2/formperma/4hFYYa0PPwcOBvnGNzKXyAjc9qjIGb5W8rqQ94AoQIM"
        frameborder="0"
        width="99%"
        height="1000px"
      ></iframe>

      <p>
        <strong>Visit one of our locations:</strong><br />
        <strong>Business Hours</strong><br />
        Monday – Friday: 8:00 a.m. – 8:00 p.m.<br />
        Saturday: 8:00 a.m. – 5:00 p.m.
      </p>

      <p><strong>Mail us at:</strong></p>

      <p>
        Cable Bahamas Limited<br />
        Attn: Complaints Handling Customer Care Department<br />
        Robinson Road P.O. Box CB 13050<br />
        Nassau
      </p>

      <p>
        Business Hours: 8:00am – 8:00pm, Monday – Friday and 8:00am – 5:00pm,
        Saturday
      </p>

      <ul>
        <li>Telephone: <a href="tel:+12426012200">601-2200</a></li>
        <li>Facsimile: <a href="tel:+12426018990">601-8990</a></li>
      </ul>

      <h4>What we’ll do and when we’ll do it</h4>

      <p>
        Once a complaint is received by us, we will issue the complaint a unique
        identifier code, so you can track your complaint up to its resolution.
      </p>

      <p>
        A complaint will be acknowledged within five (5) days of its receipt by
        us, at which time we will advise when you can expect a response to your
        complaint; in all cases, we aim to have complete resolution within
        thirty (30) business days.
      </p>

      <p>
        If our Customer Care Representatives cannot resolve your complaint
        within an acceptable time, it will be escalated. Our Manager of Customer
        Care will then work with you to try and resolve your problem. If that
        doesn’t work, we’ll involve the Director of Customer Care or the VP. We
        settle most outstanding complaints at this stage, but if not, we’ll
        explain our final position.
      </p>

      <p>
        You can ask for the Manager of Customer Care or someone else in the
        escalation hierarchy to review your complaint at any time if the
        Customer Care Representative has not been able to help and has not
        already offered to escalate your complaint.
      </p>

      <p>
        When CBL has resolved your complaint or a firm decision is made relating
        to your complaint, you will be notified as soon as possible.
      </p>

      <p>The Complaint Handling Procedure involves the following stages:</p>

      <ul>
        <li>Complaint is lodged by a customer;</li>
        <li>
          Complaint is acknowledged within 5 business days of its receipt;
        </li>
        <li>The complaint is investigated;</li>
        <li>Complaint is escalated internally, if necessary</li>
        <li>Customer is notified of a resolution;</li>
        <li>Complaint is deemed closed by CBL</li>
      </ul>

      <p>
        CBL will deem a complaint resolved/closed in the following
        circumstances:
      </p>

      <ul>
        <li>
          The complaint has been resolved and the customer is no longer
          dissatisfied;
        </li>
        <li>
          The customer clearly indicates that they no longer wish to pursue the
          complaint;
        </li>
        <li>
          We have agreed with the customer on a course of action that would
          resolve the complaint to the customer’s satisfaction;
        </li>
        <li>
          We have done all that we can, but the customer and CBL cannot agree on
          a course of action or a way forward.
        </li>
      </ul>

      <h4>What you can do if you’re still dissatisfied</h4>

      <p>
        If you’re still dissatisfied having followed the process explained
        above, and thirty (30) business days have passed since you complained,
        you can refer your complaint to&nbsp;<a
          href="http://www.urcabahamas.bs/"
          target="_blank"
          rel="noopener noreferrer"
          >URCA</a
        >.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComplaintsProcedure",
};
</script>

<style scoped>
body {
  padding: 20px;
}

.page-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.page-content-wrapper {
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.page-content-wrapper p,
.page-content-wrapper ul {
  margin-bottom: 1rem;
}

.page-content-wrapper ul {
  padding-left: 20px;
  list-style-type: disc;
}

.form-group {
  margin-bottom: 1rem;
}

textarea {
  resize: vertical;
}

.btn {
  margin: 1rem 0px;
  border-radius: 6rem;
  background-color: #635890;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .page-title {
    font-size: 1.5rem;
  }

  .page-content-wrapper {
    padding: 10px;
  }

  .form-group input,
  .form-group textarea {
    font-size: 0.9rem;
  }

  .btn {
    width: 100% !important;
    border-radius: 6rem;
  }
}
</style>
