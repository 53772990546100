<template>
  <div
    class="horizontal-page-margin vertical-page-margin section-margin-xlarge"
  >
    <div class="main-title">{{ bundle?.bundleName }}</div>
    <div class="main-description">
      <RichText :document="bundle?.description"></RichText>
    </div>
    <ImageWithText
      class="section-margin-small"
      :imageUrl="bundle?.images?.fields?.file?.url"
    >
      <div class="bundle-details">
        <div class="price-block">
          <span class="price">{{ bundle?.price?.price }}</span>
          <span class="price vat">{{ bundle?.price?.vat }}</span>
        </div>
        <RichText
          class="bundle-description"
          :document="bundle?.price?.description"
        ></RichText>
        <div class="value-prop-container">
          <div v-if="bundle?.price?.valueProp1" class="value-prop-line">
            <div
              class="icon"
              :style="{
                backgroundImage: `url(${bundle?.price?.valueProp1Icon?.fields?.file?.url})`,
              }"
            ></div>
            <span class="value-prop">{{ bundle?.price?.valueProp1 }}</span>
          </div>
          <div v-if="bundle?.price?.valueProp2" class="value-prop-line">
            <div
              class="icon"
              :style="{
                backgroundImage: `url(${bundle?.price?.valueProp2Icon?.fields?.file?.url})`,
              }"
            ></div>
            <span class="value-prop">{{ bundle?.price?.valueProp2 }}</span>
          </div>
          <div v-if="bundle?.price?.valueProp3" class="value-prop-line">
            <div
              class="value-prop-icon"
              :style="{
                backgroundImage: `url(${bundle?.price?.valueProp3Icon?.fields?.file?.url})`,
              }"
            ></div>
            <span class="value-prop">{{ bundle?.price?.valueProp3 }}</span>
          </div>
          <div v-if="bundle?.price?.valueProp4" class="value-prop-line">
            <div
              class="icon div-icon-image"
              :style="{
                backgroundImage: `url(${bundle?.price?.valueProp4Icon?.fields?.file?.url})`,
              }"
            ></div>
            <span class="value-prop">{{ bundle?.price?.valueProp4 }}</span>
          </div>
        </div>
        <div class="button hover-effect-noshadow">
          <RichText :document="bundle?.price?.buttonText"></RichText>
        </div>
      </div>
    </ImageWithText>
    <div class="section-margin">
      <div class="whats-included">{{ bundle?.whatsIncludedSection }}</div>
      <div
        class="included-details"
        v-for="(item, index) in bundle?.bundleDetails"
        :key="index"
      >
        <div
          class="bundle-image div-icon-image"
          :style="{
            backgroundImage: `url(${item?.image?.fields?.file?.url})`,
          }"
        ></div>
        <div class="included-description">
          <div class="included-title">{{ item?.categoryName }}</div>
          <RichText
            class="included-copy"
            :document="item?.description"
          ></RichText>
          <div
            class="benefits"
            v-for="(benefit, index) in item?.benefits"
            :key="index"
          >
            <span
              class="div-icon-image icon"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/icons/green_check.svg') + ')',
              }"
            ></span>
            <span class="benefit">{{ benefit }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageWithText from "@/components/common/ImageWithText.vue";
import RichText from "@/components/common/RichText.vue";
import { getBundleByName } from "@/util/bundlesUtil.js";

export default {
  name: "BundleDetails",
  inject: ["$contentful"],
  components: {
    RichText,
    ImageWithText,
  },
  data() {
    return {
      fields: {},
      initalBundleIds: [],
      bundle: {},
    };
  },
  async mounted() {
    setTimeout(async () => {
      this.bundle = await getBundleByName(decodeURI(this.$route.params.bundle));
    }, 100);
  },
};
</script>

<style scoped lang="scss">
.main-title {
  color: #000;
  font-size: $font-l;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
}

.main-description {
  color: #4f4f4f;
  font-size: $font-s;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  margin-top: 5px;
}

.bundle-details {
  border-radius: $border-radius;
  border: 1px solid #e0e0e0;
  background: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 24px;
}

.price-block {
  margin-top: 10px;
}

.price {
  color: #635890;
  font-size: $font-l;
  font-style: normal;
  font-weight: 700;
  line-height: $font-l;
}

.vat {
  margin-left: 10px;
}

.bundle-description {
  color: #4f4f4f;
  font-size: $font-xxs;
  font-style: normal;
  font-weight: 450;
  line-height: 16px;
  margin-top: 8px;
}

.value-prop-container {
  margin: 30px 0;
}

.value-prop-line {
  display: flex;
  margin-top: 15px;
}

.value-prop-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
}

.value-prop {
  color: #000;
  font-size: $font-xxs;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  display: inline-block;
  margin-left: 16px;
  height: 24px;
}

.button {
  border-radius: 100px;
  background: #ffc627;
  color: #1d1603;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
}

a {
  text-decoration: none;
}

.whats-included {
  color: #000;
  font-size: $font-l;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
}

.included-details {
  border-radius: $border-radius;
  border: 1px solid #e0e0e0;
  background: #fff;
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}

.bundle-image {
  min-width: 223px;
  min-height: 170px;
  margin: 25px;
}

.included-description {
  margin: auto 25px;
}

.included-title {
  color: #635890;
  font-size: $font-s;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.included-copy {
  color: #000;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  margin-top: 8px;
}

.benefits {
  white-space: nowrap;
  margin-bottom: 10px;
}

.icon {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  vertical-align: top;
}

.benefit {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  color: black;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}
</style>
