<template>
    <div class="terms-conditions">
      <h1>ALIV Mobile Policy</h1>
  
      <section>
        <p>
          Thank you for choosing NewCo2015 Limited, trading as and referred to throughout these Terms &
          Conditions (“T&Cs”) as Aliv. Please read these T&Cs, which contain important information about your
          relationship with Aliv. These T&Cs set out the basic terms and conditions upon which we will provide Our
          Services to you. You will become bound by these provisions once you accept these T&Cs.
        </p>
        <p>
          In these T&Cs, the terms “I”, “My”, “You” and “Your” will be used to refer to the Customer and the terms
          “We, “Us” and “Our” will be used to refer to Aliv.
        </p>
        <p>
          These T&Cs, together with the Service Description (Annex 1), the Fair Use Policy (Annex 2), and the Rate
          Plan Tables, form the Agreement with us.
        </p>
        <p>
          The Service Description is a detailed description of the services we offer, including the different features,
          options, and availability of a service.
        </p>
        <p>
          The Rate Plan Tables set out the fees or charges. We may charge you for Your Use of Our Services. They
          also contain other information, such as pricing plans and some specials we offer. Please check the Rate
          Plan Tables carefully to see what fees and charges apply to your use of the service. Please visit our
          website www.bealiv.com for more information on Our Rate Plan Tables. Our Rate Plans will only apply
          when you are connected to our network.
        </p>
        <p>
          The Fair Use Policy contains further information that will apply to certain designated Plans as indicated
          on the Rate Plan Tables.
        </p>
      </section>
  
      <section>
        <h2>WHAT CHARGES WILL APPLY TO MY USE OF THE SERVICES?</h2>
        <p>
          We have several options available to you as to the charges that we will apply for your use of the services.
          The choice is yours as to which Rate Plan will apply for use of the services. The different Rate Plan
          options are outlined in the Rate Plan Tables. The standard Rate Plan shall apply if you have not
          purchased a Freedom, Liberty, or Liberty Global Plan using one of the available methods of payment.
        </p>
      </section>
  
      <section>
        <h2>WHO IS THIS AGREEMENT WITH?</h2>
        <p>
          This Agreement is between You and Us, Our assignees, and agents for the provision of Mobile voice,
          SMS, and data services (“the Service Offering”) on Our mobile network. This Agreement, the Services,
          and the use of Your receiving Equipment (“the Device”) are subject to applicable fees and conditions.
          Your use of the Services will constitute your agreement to be bound by these T&Cs.
        </p>
      </section>
  
      <section>
        <h2>WHAT IS INCLUDED IN THESE TERMS AND CONDITIONS?</h2>
        <p>In these T&Cs, you’ll find important information about:</p>
        <ul>
          <li>ALIV services provided to you (“Services”);</li>
          <li>Any equipment for which we provide service, such as a phone, handset, tablet, or SIM card (collectively,
            a “Device”);</li>
          <li>Any charges, taxes, fees, and other amounts we bill you or that were accepted or processed through
            your device (“Charges”);</li>
          <li>Confidentiality;</li>
          <li>Network management practices;</li>
          <li>Limitations of liability;</li>
          <li>Resolution of complaints; and</li>
          <li>Other terms specified by Us, which may be updated from time to time and posted on Our website at
            <a href="https://www.bealiv.com/" target="_blank">https://www.bealiv.com/</a>.</li>
        </ul>
      </section>
  
      <section>
        <h2>HOW DO I ACCEPT THESE T&Cs?</h2>
        <p>You accept these T&Cs by doing any of the following things:</p>
        <ul>
          <li>Giving us a written or electronic signature or confirmation, or telling us orally that you accept;</li>
          <li>Activating, using or paying for the Service or a device; or</li>
          <li>Opening the Device box or package.</li>
        </ul>
        <p>
          If you don’t want to accept these T&Cs, don’t do any of these things. When you accept, you’re telling us
          that you are of legal age (which means you are 18 years or older. If you accept for an organization, you’re
          telling us that you are authorized to bind that organization, and references to “You” in these T&Cs may
          mean the organization.
        </p>
      </section>
  
      <section>
        <h2>WHAT HAPPENS IF THERE IS AN INCONSISTENCY BETWEEN THE DIFFERENT PARTS OF THE
          AGREEMENT?</h2>
        <p>
          If anything in these T&Cs is inconsistent with a provision in another part of the Agreement, then unless
          otherwise stated, these T&Cs prevail to the extent of the inconsistency.
        </p>
      </section>
  
      <section>
        <h2>HOW CAN I PAY?</h2>
        <p>
          The methods of payments include cash, credit card, debit card, and electronic payments, including our
          mobile app. You will also be able to top up your account at any of Our authorized agents. Topping up
          Your account will allow you to purchase Rate Plans or to use the money on your account in accordance
          with our Out of Plan Rates as outlined on our website.
        </p>
      </section>
  
      <section>
        <h2>WHAT HAPPENS IF MY DEVICE IS LOST OR STOLEN?</h2>
        <p>
          You agree to notify us if your device is lost or stolen. Once you notify us, we will suspend your service. If
          charges are incurred before you notify us, you are not liable for charges you did not authorize. However,
          the fact that your device or account was used is some evidence of authorization. You may request us to
          investigate charges you believe were unauthorized. We may ask you to provide information and you may
          submit information to support your request. If we determine the Charges were unauthorized, we will
          credit your account. If we determine the Charges were authorized, we will inform you within 30 days and
          you will remain responsible for the Charges. If you request that we not suspend your service, you will
          remain responsible for all charges incurred.
        </p>
      </section>
  
      <section>
        <h2>WHAT ARE THE PERMITTED AND PROHIBITED USES FOR MY DEVICE AND THE SERVICE?</h2>
        <p>
          Our wireless network is a shared resource, which we manage for the benefit of all our customers. If you
          purchase a Rate Plan that enables the use of our data services, such use is intended for Web browsing,
          messaging, and similar activities. Certain activities and uses of our services and your device are
          permitted and others are not. The prohibited uses of our services include:
        </p>
        <ul>
          <li>Using Our services to engage in telemarketing practices or call center operations;</li>
          <li>Reselling Our services purchased to anyone else;</li>
          <li>Sharing subscriptions between numerous users via a PBX, call center, computer, SIM box or by any
            other means;</li>
          <li>Using Our services to infringe, misappropriate or violate any right protected by any copyright,
            trademark, or any other applicable intellectual property right;</li>
          <li>Using Our services to transmit content that is deemed offensive, abusive, defamatory, indecent,
            obscene, unlawful, harassing, menacing, or otherwise inappropriate or unacceptable under applicable
            laws and/or regulation;</li>
          <li>Using Our services to publish, submit, receive, upload, download, post, use, copy or otherwise
            reproduce, transmit, re-transmit, distribute or sole content considered indecent for minors;</li>
          <li>Using Our services to interfere, gain unauthorized access or violate the security of network users,
            network hosts or network equipment, or to gain unauthorized access to any public or private network
            data;</li>
          <li>Using Our services for other than your individual communications, calling numbers (whether
            individually, sequentially, or automatically) to generate income for yourself or others as a result of
            merely placing the call; or</li>
          <li>Unusual calling patterns inconsistent with common, individual subscriptions use, for example, making
            regular short calls or making calls to multiple numbers in unusually short periods of time.</li>
        </ul>
        <p>
          It is not our policy to monitor or control communications. However, if We become aware of the
          above-prohibited uses, we may block or filter sites, or cooperate with and notify the appropriate
          authorities and parties, including, but not limited to, law enforcement agencies, and other service
          providers or carriers in accordance with the laws of the Commonwealth of The Bahamas. For legal
          purposes where required by the law, we will reveal Your identity or other Data We hold regarding the use
          of service to the competent Bahamian regulatory authority. In cases where we receive a Court Order, in
          accordance with the laws of The Commonwealth of The Bahamas, requesting us to disclose the identity
          of a customer who has misused the Service, we will do so. We may implement technical mechanisms to
          prevent prohibited uses of our services, where technically feasible. If You violate the permitted uses of
          our services, you will be required to cover all expenses which are reasonably incurred as a result of the
          violation.
        </p>
      </section>
  
      <section>
        <h2>CAN I ROAM ON MY DEVICE?</h2>
        <p>
          <strong>National Roaming:</strong> Your Device may connect to BTC’s network (“Off-Net”). This may become available to
          you in the future at certain times and at certain locations. Please check Our Website for more
          information and updates.
        </p>
        <p>
          <strong>International Roaming & Dialing:</strong> Availability and features offered for international roaming and dialing
          vary depending on your Rate Plan and device. All countries may not be available for roaming and
          available countries may change from time to time. Go to Our Website for information on international
          access, rates, services, and coverage. All SIM cards will be provisioned to enable international roaming.
          Charges for international calls will vary according to which Rate Plan you have chosen.
        </p>
      </section>
  
      <section>
        <h2>CAN ALIV CHANGE MY SERVICES OR THIS AGREEMENT?</h2>
        <p>
          Yes. We may change, limit, suspend or terminate your Service or this Agreement at any time, including if
          you engage in any of the prohibited uses described in Our Fair Use Policy.
        </p>
        <p>
          We reserve the right to and may vary all Agreements and Policies, including but not limited to changes in
          Charges, Fees or the introduction of new Charges.
        </p>
        <p>
          We will provide you with at least thirty (30) days’ notice of any change.
        </p>
        <p>
          You accept any and all changes by your continued use of Our Services after the date of the change. If You
          do not agree with the changes, you must contact us at least ten (10) days prior to the effective date of
          the modification to discontinue the affected service. We shall not be responsible to you for any claims by
          you as a consequence of such changes.
        </p>
        <p>
          Under certain limited circumstances, we may also block your device from working on our network. If the
          change to Your Service or Rate Plan will have a material adverse effect on you, we will provide you with
          at least one month’s notice of the change.
        </p>
      </section>
  
      <section>
        <h2>CAN ALIV SUSPEND MY SERVICE?</h2>
        <p>Yes. We may suspend the Services in any of the following circumstances:</p>
        <h3>Suspension with notice</h3>
        <ul>
          <li>In order to carry out the maintenance or testing of the Network;</li>
          <li>When it is necessary to safeguard the security and integrity of the Network or to reduce the incidence
            of fraud; or</li>
          <li>Any other circumstances requiring a planned service interruption</li>
        </ul>
        <h3>Suspension without notice</h3>
        <p>We may, without prejudice to our other rights hereunder, suspend or disconnect the Services without
          notice in any of the following circumstances:</p>
        <ul>
          <li>During any technical failure of the Network (unplanned interruption);</li>
          <li>Because of an emergency or upon instruction by emergency services or any government or appropriate
            authority or for your own or other user’s security (unplanned interruptions);</li>
          <li>If You fail to comply with the terms of this Agreement, or if there is a breach by You of the Fair Use Policy,
            after being given written notice of the failure (including but not limited to a failure to pay any Charges
            due hereunder); or</li>
          <li>If You use our services for any of the prohibited uses outlined above.</li>
        </ul>
        <p>
          We shall endeavor to keep all such suspensions to a minimum and shall give you notice of such
          suspensions (usually by SMS notice), except in cases of fraud, persistent late, and non-payment. If We
          suspend Your Services for breach of the Fair Use Policy, we shall restore the Services if the circumstance
          is remedied.
        </p>
      </section>
  
      <section>
        <h2>CAN I TERMINATE MY SERVICES OR THIS AGREEMENT?</h2>
        <p>Yes. You may terminate this Agreement by providing to Us thirty (30) days’ notice in writing in the event
          that We:</p>
        <ul>
          <li>Have committed a material breach of this Agreement that is incapable of remedy.</li>
          <li>Have committed a material breach of this Agreement that is capable of remedy, and we have failed to
            commence remedying that breach within thirty (30) days of You supplying written notice specifying the
            breach and requiring Us to remedy it.</li>
        </ul>
      </section>
  
      <section>
        <h2>CAN ALIV TERMINATE MY SERVICES OR THIS AGREEMENT?</h2>
        <p>
          Yes. After one hundred and twenty days (120) of inactivity, your account and services provided to you by
          us will be terminated without notice, and any remaining credit on your device will be claimed by Us. We
          may terminate this Agreement (in whole or in part) by providing thirty (30) days’ notice in writing if:
        </p>
        <ul>
          <li>You commit a material breach of this Agreement that is incapable of remedy;</li>
          <li>You commit a material breach of this Agreement that is capable of remedy but fail to remedy that breach
            within thirty (30) days of us supplying written notice specifying the breach and requiring its remedy; or</li>
          <li>If any of the events in these Terms and Conditions occur.</li>
        </ul>
        <p>for as little as $5 a month, you can retain your number.</p>
      </section>
  
      <section>
        <h2>WHAT HAPPENS WHEN MY SERVICES ARE TERMINATED?</h2>
        <p>
          If Your Service is terminated by you or us, you will remain liable for all charges related to the Service until
          it is terminated, including any penalties.
        </p>
        <p>
          Termination of this Agreement or the expiry of Your Service for whatever reason shall not affect:
        </p>
        <ul>
          <li>The rights and obligations of the parties which have accrued prior to such termination or expiration; or</li>
          <li>Any provisions of this Agreement which are of a continuing nature and any other provisions of this
            Agreement are necessary for their interpretation or enforcement.</li>
        </ul>
        <p>On termination or expiration of this Agreement or Your Service:</p>
        <ul>
          <li>Any sums properly due from one party to the other will become payable within thirty (30) days of
            termination (including Termination Fees); and</li>
          <li>You shall cease using the Services.</li>
        </ul>
        <p>
          Following termination of your account for any reason, your number may be re-assigned after a period of
          someone hundred and twenty (120) days. We are not liable to You for any expense or loss incurred by
          You due to re-assignment. If your service or account is terminated and then reinstated, you may be
          charged a reactivation fee.
        </p>
      </section>
  
      <section>
        <h2>YOUR CONSENT TO BE CONTACTED</h2>
        <p>
          Unless You have requested not to receive voice, written, or electronic sales and marketing messages
          from Us at the time of applying for Our Services, we may contact you without charge, on any wireless
          telephone number assigned to your account for any purpose, including marketing messages, and in any
          manner permitted by law. You also expressly consent to be contacted by us, and anyone contacting you
          on our behalf, for any purpose, including billing, collection, or other account or service-related purpose,
          at any telephone number or physical or electronic address where you may be reached, including any
          wireless telephone number. You agree that ALIV, and anyone contacting you on our behalf, may
          communicate with you in any manner, including using a pre-recorded or artificial voice or using an
          automatic telephone dialing system to place calls or send messages, alerts, or an automatic e-mail
          system to deliver email messages. If a contact number you have provided to us is no longer your number,
          you agree to notify us promptly that you can no longer be reached at that number. You represent that
          you have received, and are authorized to convey to us, the consent of any authorized users on your
          account to be contacted by us, as described in this section. You agree that all consents provided in this
          section will survive the cancellation of your service and account.
        </p>
      </section>
  
      <section>
        <h2>HOW DO WE REACH EACH OTHER?</h2>
        <p>
          You may contact us at customercare@bealiv.com, by calling 611 from your Device, or by writing to Aliv,
          Phil Keeping Building, Old Trail Road off Soldier Road P.O. Box CB-13050, Nassau, The Bahamas.
        </p>
      </section>
  
      <section>
        <h2>HOW CAN I DISPUTE MY CHARGES?</h2>
        <p>
          If you have any questions about or want to dispute any Charges, please refer to our Customer
          Complaints Process at customercare@bealiv.com, by visiting one of our Aliv stores, by calling 611 from
          your Device, or by writing to Aliv Customer Relations, P.O. Box 13050, Phil Keeping Building, Old Trail
          Road off Soldier Road, Nassau, The Bahamas. If this does not fix things, please notify us in writing. You
          must notify us in writing of any dispute regarding charges to your account within 60 days after the date
          you first receive the Charge. If you don’t, you may not pursue a claim in arbitration or in court. If you
          accept a credit, refund, or other compensation or benefit to resolve a disputed charge, you agree that
          the issue is fully and finally resolved. You must pay disputed charges until the dispute is resolved.
        </p>
      </section>
  
      <section>
        <h2>HOW DO I RESOLVE COMPLAINTS WITH ALIV?</h2>
        <p>
          If You have any complaints in connection with Our Services, you may complain in writing (including by
          sending an email) via the Website or to customercare@bealiv.com or by calling 611 from Your Device.
          We will handle Your complaint in accordance with our complaint’s procedure. You may obtain a copy of
          this procedure from Our Website. We will do our best endeavors to resolve your complaint. However, if
          we cannot resolve your complaint to your satisfaction, you can take your complaint to the Utilities
          Regulation and Competition Authority (URCA) for resolution.
        </p>
      </section>
  
      <section>
        <h2>GENERAL</h2>
        <p>MOBILE NUMBER PORTABILITY</p>
        <p>
          Mobile Number Portability is the ability of a customer to change from one mobile service provider to
          another mobile service provider without changing their mobile number subject to the terms and
          conditions herein appearing. When available, should you desire to port your mobile number, you will be
          required to submit a ‘port in’ request to us by signing a mobile services porting form. Mobile Number
          Portability is offered subject to you adhering to and agreeing to our application form conditions. Your
          request to port in shall be treated as a new application for Services and is subject to Our terms and
          conditions herein contained and represent a notice to terminate any agreement that You have with Your
          current mobile service operator at the time. We shall not be responsible and/or liable for any
          interruption and/or delay in approving and/or providing services to you caused by your current mobile
          service operator.
        </p>
      </section>
  
      <section>
        <h2>CONFIDENTIALITY</h2>
        <p>
          We are committed to and observe the provisions of the Data Protection (Privacy of Personal Information)
          Act, 2003 and any other applicable local statute, as it applies to the privacy of Your Personal Information.
        </p>
        <p>
          We will not divulge Confidential Information to any Third Party except to contractors, suppliers, and
          agents, strictly on a need-to-know basis, for the purposes of the implementation and/or performance of
          this Agreement, unless otherwise agreed in writing with Us. We shall be entitled to keep records of
          Customer Information, which we shall use to perform our obligations under this Agreement, and for
          related purposes. We shall be entitled to disclose Customer Information as required by any legal,
          regulatory or financial regulatory agencies or by court order, or any third party (some of whom may be
          outside of The Bahamas), for the purposes of providing the Services.
        </p>
        <p>
          The obligation of confidentiality shall apply in the period commencing on the Commencement Date and
          ending six (6) years after the termination or expiration of this Agreement.
        </p>
        <p>
          For further information regarding our practices of confidentiality, please read our Privacy Policy on Our
          Website at www.bealiv.com.
        </p>
      </section>
  
      <section>
        <h2>INTELLECTUAL PROPERTY</h2>
        <p>
          The Intellectual Property rights, including copyright and trademarks that exist in Services, software, and
          Our Equipment are owned by Us and Our licensors and are protected by Bahamian and international
          laws. By supplying you with services and software, we are not transferring or assigning ownership of any
          Intellectual Property rights in or relating to the Services and or software.
        </p>
        <p>
          Where We create Intellectual Property rights during or as a result of the supply by Us of Services,
          software, and devices to you, we shall own all such Intellectual Property rights.
        </p>
        <p>
          You agree not to infringe, misappropriate, discredit, dilute or violate Our Intellectual Property rights or
          any third party in any way. Except for a limited license to use the Services. Your purchase of Services does
          not grant you any license to copy, modify, reverse engineer, download, redistribute, or resell our
          intellectual property or the intellectual property of others related to the Services.
        </p>
        <p>
          Intellectual Property may be used only with Our Services unless expressly authorized by us.
        </p>
      </section>
  
      <section>
        <h2>DISCLAIMER OF WARRANTIES</h2>
        <p>
          Except for any written warranty that may be provided with a smart device You purchase from Us, and to
          the extent permitted by law, the services and devices are provided on an “as is” and on a “with all faults”
          basis and without warranties of any kind. We make no representations or warranties, express or implied,
          regarding:
        </p>
        <ul>
          <li>Merchantability or fitness for a particular purpose concerning Your Service;</li>
          <li>Correct routing or completion without error or interruption of transmissions, including but not limited to
            data, voice, or any package transmitted on Our Network;</li>
          <li>Network security;</li>
          <li>Encryption employed by service;</li>
          <li>Integrity of data sent, backed up, stored, or load-balanced;</li>
          <li>That Our security procedures at an acceptable level will prevent the loss, alteration of, or improper
            access to data; and</li>
          <li>Service-free errors.</li>
        </ul>
        <p>
          We do not authorize anyone to make any warranties on our behalf.
        </p>
      </section>
  
      <section>
        <h2>NETWORK MANAGEMENT PRACTICES</h2>
        <p>
          We want to ensure that our customers’ network experience is optimized so that as many people as
          possible can enjoy using their phones for doing the things that they love. We are committed to ensuring
          that the network maintenance may be conducted on our network and on the supplier’s network. We will
          try to conduct scheduled maintenance on our network outside normal business hours, but we may not
          always be able to do so. Should network maintenance be conducted outside normal business hours, we
          will contact You in advance of any maintenance.
        </p>
      </section>
  
      <section>
        <h2>LIMITATION OF LIABILITY</h2>
        <p>
          You and we each agree to limit claims for damages or other monetary relief against each other to direct
          and actual damages regardless of the theory of liability. This means that neither of us will seek any
          indirect, special, consequential, treble, or punitive damages from the other. This limitation and waiver
          also applies to any claims you may bring against any other party to the extent that we would be required
          to indemnify that party for such a claim. You agree we are not liable for problems caused by you or a
          third party, or by any act of God. You also agree we aren’t liable for missed or deleted voice mails or
          other messages, for any information (like pictures) that gets lost or deleted if we work on your Device, or
          for failure or delay in connecting a call or text to 911 or any other emergency service. All claims must be
          brought within 2 years of the date the claim arises.
        </p>
        <p>
          The maximum liability for all Your claims relating to a Service provided pursuant to this Agreement,
          whether for breach of contract, breach of warranty, or in tort, including negligence, will be limited to the
          total sum of the value of the Charges paid or payable under the relevant Service Offering during the
          previous 12-month period under which the Service that is the subject of the claim is supplied.
        </p>
      </section>
  
      <section>
        <h2>INDEMNIFICATION</h2>
        <p>
          You agree to defend, indemnify, and hold Us and Our directors, officers, and employees harmless from
          any claims arising out of the use of the Service or Devices, breach of the Agreement, or violation of any
          laws or regulations or the rights of any third party by You, any person on Your account or that You allow
          to use the Service or Your Device.
        </p>
      </section>
  
      <section>
        <h2>SEVERABILITY</h2>
        <p>
          If a clause or condition of this Agreement is not legally effective, the remainder of this Agreement shall
          be effective. We are able to replace it with one of similar meaning that is legally effective.
        </p>
      </section>
  
      <section>
        <h2>WAIVER</h2>
        <p>
          Failure or delay by either of us to this Agreement to exercise or enforce any right, power, or remedy
          under it shall not be deemed to operate as a waiver of any such right, power, or remedy; nor shall any
          single or partial exercise by either of us operate to bar the exercise or enforcement thereof or of any
          right, power or remedy on any later occasion.
        </p>
      </section>
  
      <section>
        <h2>FORCE MAJEURE</h2>
        <p>
          We shall not be liable for any loss or damage, delay or failure in performance of the Services or facilities
          furnished due to any cause beyond Our reasonable control such as, but not limited to, acts of God, acts
          of Government, military authority or other competent authority, telecommunications network
          operators, war or national emergency riots, civil commotion, fire, explosion, accidents, flood,
          earthquakes, hurricanes, lightning, extremely severe weather, magnetic storms, epidemic, power failures
          or blackouts, lock-outs, strikes and other industrial disputes (whether of the Company or others), acts of
          terrorism, or acts of vandalism or acts otherwise known as “Force majeure”. You agree to hold Us free
          from any liability as a result of any direct or indirect consequence of an event of Force majeure and in
          the event of any claims hereunder, You shall prove that the liability arose independently of and was in no
          way connected with or occasioned by or contributed or traceable to any of the said occurrences or any
          consequence thereof and in default of such proof, We shall not be liable to make any credit, prorating or
          refund in respect of such claims.
        </p>
      </section>
  
      <section>
        <h2>ONLINE TRANSACTION SERVICES</h2>
        <p>
          When you log in to MyALIV, you will have access to top up your account to use ALIV services such as talk
          minutes, text, and data. The purchase of handsets is not currently available on our website.
        </p>
        <h3>Refund/Return policy</h3>
        <p>
          You can only topup your phone and purchase phone plans on our website. Because these are virtual
          products, these cannot be refunded. If you have any issues regarding your ALIV plan or handset, please
          contact us. For all other services, please visit us in-store.
        </p>
        <h3>Export restrictions</h3>
        <p>We currently do not export any ALIV goods or services outside of The Bahamas.</p>
        <h3>Delivery policy</h3>
        <p>
          You can only top up your phone and purchase phone plans on our website. Because these are virtual
          products, these are not delivered. If you have any issues regarding your ALIV plan or handset, please
          contact us. For all other services, please visit us in-store.
        </p>
      </section>
  
      <section>
        <h2>GOVERNING LAW</h2>
        <p>
          This Agreement is governed by the laws of The Commonwealth of the Bahamas. The parties submit to
          the exclusive jurisdiction of the courts of The Commonwealth of The Bahamas.
        </p>
      </section>
  
      <section>
        <h2>Annex 1</h2>
        <h3>SERVICE DESCRIPTION</h3>
        <p>
          Aliv provides users with electronic communications services over its LTE (Band 13 and Band 4)
          high-speed voice and data network using fibre optic for IP site backhaul. The services available include
          high-definition voice, SMS, high-speed data upload and download, voicemail, international long-distance
          services, and international roaming on partner networks. Please check our Website, www.bealiv.com, for
          the latest network coverage.
        </p>
      </section>
  
      <section>
        <h2>Annex 2</h2>
        <h3>FAIR USE POLICY</h3>
        <h4>INTRODUCTION</h4>
        <p>
          This Fair Use Policy (“Policy”) relates to Your use of our Mobile Services. It is designed to make sure we
          can give You Services that are great value, fast and reliable. This Policy applies, but is not limited, to all
          Services offered by Us. This also includes such other services that we might add in the future.
        </p>
        <p>
          By using our services, you agree to comply with this policy and remain responsible for complying with it.
          You must make sure that any use of the Services, by yourself or anyone else via your account, complies
          with this policy. You are responsible for all use of the Services through Your account and for any breach
          of this Policy whether an unacceptable use occurs or is attempted, whether You knew or should have
          known about it, whether or not You carried out or attempted the unacceptable use alone, contributed to
          or acted with others or allowed any unacceptable use to occur by omission. You agree that We are not
          responsible for any of your activities in using our network and/or services.
        </p>
        <p>
          We may change or modify this policy at any time. The changes to this Policy will be posted on Our
          Website at www.bealiv.com. Please check Our Website periodically for any revisions to this policy. If We
          make any material changes to this policy, we will give you thirty (30) days’ notice. You acknowledge and
          agree that Your access to Our Website and Your use of Our services following any posted changes to the
          Policy indicate Your acceptance of such changes.
        </p>
      </section>
  
      <section>
        <h3>EXCESSIVE NETWORK USAGE</h3>
        <p>
          Our services are for normal person to person use from your phone and are subject to your acceptance of
          our standard terms and conditions for monthly services. We monitor use in accordance with those terms
          and conditions. If you exceed more than 100GB in any month on a 30 day unlimited plan or more than
          30GB in any week on a 7 day plan, We reserve the right to throttle or reduce the speed available to You,
          and We may also suspend Your services. If We discover that You talk for more than 7,500 minutes or
          send more than 4,500 text messages per month, we reserve the right to restrict your access to calls and
          we may suspend your services. Our Fair Use Policy also restricts calls and texts to no more than two
          hundred (200) different numbers in one month. Further, calls are restricted to a maximum of two (2)
          hours per call. Once a call has reached two (2) hours, it will be automatically disconnected. However, you
          will be able to dial the same number straight away.
        </p>
      </section>
  
    </div>
  </template>
  
  <script>
  export default {
    name: 'AlivMobilePolicy',
  };
  </script>
  
  <style scoped>
  .terms-conditions {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  h1 {
    color: #635890;
  }
  
  h2 {
    color: #635890;
    margin-top: 20px;
  }
  
  h3 {
    color: #635890;
    margin-top: 15px;
  }
  
  h4 {
    color: #635890;
    margin-top: 10px;
  }
  
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  a {
    color: #635890;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  p {
    color: #666;
  }
  </style>
  