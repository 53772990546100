<template>
  <div class="fibr-faq">
    <ImageWithText
      :imageUrl="fields?.image?.fields?.file?.url"
      :leftAlignImage="false"
    >
      <div class="sub-heading">{{ fields.name }}</div>
      <div class="section-title">{{ fields.heading }}</div>
      <RichText class="faq-text" :document="fields.descriptionRt"></RichText>
    </ImageWithText>
    <ImageWithText
      class="section-margin"
      :imageUrl="downloadApp?.sectionImage?.fields?.file?.url"
      :minHeight="`500px`"
    >
      <div class="sub-heading">{{ downloadApp.homepageSectionName }}</div>
      <div class="section-title">{{ downloadApp.homepageHeading }}</div>
      <RichText
        class="faq-text"
        :document="downloadApp.homepageCopyRt"
      ></RichText>
      <DownloadApp
        class="section-margin-small"
        :fields="downloadApp"
        :smallTitle="true"
      ></DownloadApp>
    </ImageWithText>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import entryId from "@/config/entryid.json";
import ImageWithText from "@/components/common/ImageWithText.vue";
import RichText from "@/components/common/RichText.vue";
import DownloadApp from "@/components/fibr-portal/DownloadApp.vue";

export default defineComponent({
  name: "FibrHomeFaq",
  inject: ["$contentful"],
  components: {
    ImageWithText,
    RichText,
    DownloadApp,
  },
  data() {
    return {
      fields: {
        name: "",
        heading: "",
        descriptionRt: {},
      },
      downloadApp: {
        homepageSectionName: "",
        homepageHeading: "",
        homepageCopy: {},
        downloadCopy: "",
      },
      image: "",
    };
  },
  created() {
    this.$contentful.getEntry(entryId.fibrHome.aboutFibr.faqs).then((val) => {
      this.fields = val.fields;
    });
    this.$contentful.getEntry(entryId.fibrHome.downloadApp).then((val) => {
      this.downloadApp = val.fields;
    });
  },
});
</script>

<style scoped lang="scss">
.sub-heading {
  text-align: left;
  margin-bottom: 16px;
}

.section-title {
  font-size: $font-l;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
}

.faq-text {
  color: #4f4f4f;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  margin-top: 8px;
  max-width: 90%;
}
</style>
