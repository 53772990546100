<template>
  <Carousel :autoplay="carouselChangeTimeSeconds * 1000" :wrap-around="true">
    <Slide v-for="slide in slides" :key="slide">
      <div
        class="carousel__item div-background-image"
        :style="{
          backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 69.17%), url(${slide.backgroundImage})`,
        }"
      >
        <div class="horizontal-page-margin banner-content">
          <div class="content">
            <div class="title">{{ slide.title }}</div>
            <div class="copy">{{ slide.copy }}</div>
            <a v-if="slide.ctaLabel" :href="slide.ctaUrl">
              <div class="cta-button hover-effect-noshadow">
                {{ slide.ctaLabel }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </Slide>
    <template #addons>
      <Pagination />
    </template>
  </Carousel>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Pagination, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import entryId from "@/config/entryid.json";

export default defineComponent({
  name: "CarouselBanner",
  inject: ["$contentful"],
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      slides: [],
      carouselChangeTimeSeconds: 5,
    };
  },
  created() {
    this.$contentful.getEntry(entryId.fibrHome.banner).then((val) => {
      this.carouselChangeTimeSeconds = val.fields.carouselChangeTimeSeconds;
      const slide1 = {};
      slide1.backgroundImage = val.fields?.backgroundImage?.fields?.file?.url;
      slide1.title = val.fields?.heading;
      slide1.copy = val.fields?.copy;
      slide1.ctaLabel = val.fields?.cta?.fields?.name;
      slide1.ctaUrl = val.fields?.cta?.fields?.url;
      this.slides.push(slide1);

      const slide2 = {};
      slide2.backgroundImage = val.fields?.image?.fields?.file?.url;
      slide2.title = val.fields?.carousel2Heading;
      slide2.copy = val.fields?.carousel2Copy;
      slide2.ctaLabel = val.fields?.cta2?.fields?.name;
      slide2.ctaUrl = val.fields?.cta2?.fields?.url;
      this.slides.push(slide2);

      const slide3 = {};
      slide3.backgroundImage =
        val.fields?.carouselBackgroundImage3?.fields?.file?.url;
      slide3.title = val.fields?.carousel3Heading;
      slide3.copy = val.fields?.carousel3Copy;
      slide3.ctaLabel = val.fields?.cta3?.fields?.name;
      slide3.ctaUrl = val.fields?.cta3?.fields?.url;
      this.slides.push(slide3);
    });
  },
});
</script>

<style scoped lang="scss">
.carousel__item {
  height: 650px;
  width: 100%;
}

.carousel__pagination {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
}

.banner-content {
  height: 100%;
  display: flex;
}

.content {
  height: fit-content;
  max-width: 420px;
  display: block;
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
}

.title {
  font-size: $font-xl;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.copy {
  font-size: $font-s;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  margin-top: 20px;
}

.cta-button {
  width: fit-content;
  padding: 12px 30px;
  border-radius: 100px;
  background: #635890;
  color: white;
  text-decoration: none;
  font-size: $font-s;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  margin-top: 30px;
}

a {
  text-decoration: none;
}
</style>

<style lang="scss">
:root {
  --vc-pgn-width: 12px;
  --vc-pgn-height: 12px;
  --vc-pgn-border-radius: 6px;
  --vc-clr-primary: #635890;
  --vc-clr-secondary: white;
  --vc-pgn-margin: 8px;
}
</style>
