<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <FibrNavCard
          :title="store.title"
          :description="store.copy"
          :url="store.url"
          :icon="store?.icon?.fields?.file?.url"
          :textColor="textColorStore"
        >
        </FibrNavCard>
      </div>
      <div class="col-md-4">
        <FibrNavCard
          :title="contact.title"
          :description="contact.copy"
          :url="contact.email"
          :icon="contact?.icon?.fields?.file?.url"
          :textColor="textColorContact"
        >
        </FibrNavCard>
      </div>
      <div class="col-md-4">
        <FibrNavCard
          :title="support.title"
          :description="support.copy"
          :url="support.url"
          :icon="support?.icon?.fields?.file?.url"
          :textColor="textColorSupport"
        >
        </FibrNavCard>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import entryId from "@/config/entryid.json";
import FibrNavCard from "./FibrNavCard.vue";

export default defineComponent({
  name: "FibrNavContainer",
  inject: ["$contentful"],
  components: {
    FibrNavCard,
  },
  data() {
    return {
      contact: {
        title: "",
        copy: "",
        email: "",
        icon: "",
      },
      store: {
        title: "",
        copy: "",
        url: "",
        icon: "",
      },
      support: {
        title: "",
        copy: "",
        url: "",
        icon: "",
      },
      textColorStore: "#053A55",
      textColorContact: "#693622",
      textColorSupport: "#4E3B07",
    };
  },
  created() {
    this.$contentful.getEntry(entryId.footer.contact).then((val) => {
      this.contact = val.fields;
      this.contact.email = "mailto:" + this.contact.email;
    });

    this.$contentful.getEntry(entryId.footer.store).then((val) => {
      this.store = val.fields;
    });

    this.$contentful.getEntry(entryId.footer.support).then((val) => {
      this.support = val.fields;
    });
  },
});
</script>

<style scoped lang="scss">
.sub-heading {
  margin-bottom: 8px;
}

.heading-dark {
  margin-bottom: 32px;
}
</style>
