<template>
  <div>
    <a :href="fields?.url">
      <div
        class="image div-background-image hover-effect"
        :style="{ backgroundImage: `url(${fields?.image?.fields?.file?.url})` }"
      ></div>
      <div class="question">{{ fields.question }}</div>
      <div class="answer">{{ fields.answer.content[0].content[0].value }}</div>
    </a>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AboutFibrCard",
  inject: ["$contentful"],
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
  },
});
</script>

<style scoped lang="scss">
.image {
  max-width: 432px;
  height: 270px;
  border-radius: $border-radius;
}

.question {
  margin-top: 32px;
  font-style: normal;
  font-weight: 700;
  font-size: $font-s;
  line-height: 24px;
  color: #000000;
}

.answer {
  margin-top: 16px;
  font-style: normal;
  font-weight: 450;
  font-size: $font-xs;
  line-height: 20px;
  color: #4f4f4f;
}

a {
  text-decoration: none;
}
</style>
