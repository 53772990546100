<template>
    <div class="privacy-policy">
      <h1>Internet Privacy Policy</h1>
  
      <section>
        <p>
          Cable Bahamas Ltd. and its subsidiaries and affiliates (collectively the “CBL Group”) value our standing as the market leader in enabling connected lifestyles and experiences. When you visit our websites and use our applications and services, we may obtain data from and about you, including personally identifiable information (“PII”). We are committed to respecting your privacy and handling the information we obtain from you appropriately and with care.
        </p>
        <p>
          This Privacy Policy describes how we collect, use, process, protect or otherwise handle information which you provide to us or that we collect on our websites, applications, and digital platforms. It also describes how we handle information that you provide to us or that we collect from you offline in our physical stores or through our customer call centers. Your access to and use of our websites, applications, digital platforms, and services are subject to your acceptance of this Privacy Policy and our terms and conditions.
        </p>
        <p>
          Please note that information that you provide to us by means of third-party websites, applications, or platforms may be collected by those third-party websites, applications, or platforms, and the information collected by such third-party websites, applications, or platforms are covered by the privacy policies of those respective third-party websites, applications, or platforms. Please also bear in mind that our websites, applications, or platforms may contain links to other websites and applications which we do not own, or control, and we are not responsible for the privacy policies or practices of those sites. You should carefully review the privacy policies of other sites that you visit.
        </p>
      </section>
  
      <section>
        <h2>Who Are We?</h2>
        <p>
          The CBL Group is comprised of various subsidiaries with diverse brands. When you create an account or use our services, pay a visit or shop in our online or physical stores, or use our applications, your information is controlled by a member of the CBL Group.
        </p>
        <p>These include the following companies:</p>
        <ul>
          <li><strong>Be ALIV Limited</strong></li>
          <li><strong>Cable Bahamas Ltd.</strong></li>
          <li><strong>Cable Freeport Ltd.</strong></li>
          <li><strong>Caribbean Crossings Ltd.</strong></li>
          <li><strong>Maxil Communications Ltd.</strong></li>
          <li><strong>Systems Resource Group Limited</strong></li>
          <li><strong>Trinity Communications (Bahamas) Ltd.</strong></li>
        </ul>
  
        <p>Brands:</p>
        <ul>
          <li><strong>ALIV</strong></li>
          <li><strong>ALIV Fibr</strong></li>
          <li><strong>Cable Bahamas</strong></li>
          <li><strong>REV</strong></li>
        </ul>
      </section>
  
      <section>
        <h2>What Information Do We Collect?</h2>
        <p>
          We collect PII which you voluntarily provide. At the time that you contract with us to provide you with our services, we may obtain from you your first name and surname, gender, date of birth, telephone numbers, mailing and email address, and a copy of your NIB and passport or driver’s license.
        </p>
        <p>
          When you create an account or log in to your account on our website or enter a promotion you may be requested to provide your first name and surname, gender, date of birth, and your email address. You will also be required to create a username and password. (For the avoidance of doubt, we do not collect your password which is known only to you)
        </p>
        <p>
          We collect your email address, if voluntarily provided, in order to:
        </p>
        <ul>
          <li>Send you information, respond to inquiries, and/or other requests or questions.</li>
          <li>Send you invitations to participate in promotions or surveys.</li>
          <li>Market to our mailing list or continue to send emails to you.</li>
        </ul>
      </section>
  
      <section>
        <h2>Other Methods of Data Collection</h2>
        <p>
          We may also collect and use information that you provide on public social media forums in order to address any complaints that you make with respect to our products or services.
        </p>
        <p>
          We may also obtain information from a third-party service provider such as a website or platform service provider about how you use our products or services on third-party platforms, applications, or devices. We may also contact you to conduct surveys or to understand your needs or to better serve you.
        </p>
      </section>
  
      <section>
        <h2>What Information Do We Collect From or About You Automatically?</h2>
        <p>
          We may collect still or video images of you captured by cameras located on or around our physical stores and may record our conversations with you when you contact our customer call centers.
        </p>
        <p>
          We may combine PII and anonymous information to create aggregate information which we use to improve and enhance the content of our websites and the services and products being provided. For example, we or our third-party service providers may compile information including but not limited to information about our website visitors to assess how you found our website, the total number of visits, and the average time spent on the website.
        </p>
      </section>
  
      <section>
        <h2>When Do We Collect Information?</h2>
        <p>
          We collect information that you provide to us when you visit our physical stores or websites or use our applications, when you access our services through third-party applications, call into our customer call centers, purchase or register or subscribe for our products and services, subscribe to a newsletter, respond to a survey, participate in public forums, fill out a form or provide us with or request information from us or request our assistance to resolve issues.
        </p>
      </section>
  
      <section>
        <h2>How Else Do We Collect Information?</h2>
        <p>
          We use cookies to operate and provide our services (including website or applications-based services), to enhance or improve your viewing experience, to remember your preferences and customize our services for you, to better understand how our products and services are being utilized and to extend the availability and visibility of our products and services.
        </p>
        <p>
          You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your web browser settings. Each browser is a little different, so look at your browser’s Help menu to learn the correct way to modify your cookies.
        </p>
        <p>
          We also collect information by means of other technology including flash cookies, pixels, tags, software development kits, applications, program interfaces, and web beacons. We also collect information from you using analytics tools when you visit our websites and use our applications or contact us or use our applications on or otherwise contact us using third-party websites or platforms.
        </p>
      </section>
  
      <section>
        <h2>How Does the CBL Group of Companies Use Your Information?</h2>
        <p>
          As part of the CBL Group, we receive information from, and share information with, other CBL Group Companies. We may use the information we receive from them, and they may use the information we share with them, to help provide our services and products to you, improve, understand, customize, support, and market our services and products and their offerings to you.
        </p>
        <p>
          Additionally, we and other CBL Group Companies may use the information we collect from you to:
        </p>
        <ul>
          <li>Provide you with our products and services.</li>
          <li>Operate, develop, test, optimize, and improve our operations, websites, applications, products, and services in order to better serve you. We may use third-party administered survey research and analytics tools for these purposes.</li>
          <li>Administer a contest, promotion, survey, or other site feature for a CBL Group Company.</li>
          <li>Advertise Group products and services or send you offers and promotions about our products and services.</li>
          <li>Provide you with customer support and respond to your customer service requests and conduct trouble-shooting activities.</li>
          <li>Communicate with you by telephone, email, or Short Service Message (“SMS”) regarding your transactions, your account, or send you your bills.</li>
          <li>Understand how you use our products and services and improve the quality of our services and products and those offered by other CBL Group Companies are used.</li>
          <li>Manage the security of our systems, or to detect, investigate, and prevent fraud or other illegal or suspicious activity.</li>
          <li>Monitor your usage for quality control or violations of our terms and conditions, policies for fair and acceptable use, and other policies the use of our services which may be established from time to time.</li>
          <li>Send you information regarding or notify you about changes to our website, products, and services or changes to our terms and conditions or policies and provide you with other important information.</li>
          <li>Ask you about your experience using our services or for quality control purposes.</li>
        </ul>
      </section>
  
      <section>
        <h2>Third Party Disclosure</h2>
        <p>
          We are subject to and will observe the requirements under the Data Protection (Privacy of Information) Act 2003. We will take all commercially reasonable measures to keep your information safely stored and will not share this with anyone other than as is disclosed to you in this policy (unless we are legally required to do so) without your permission. Wherever we transfer, store or process your information. We will not store your credit card or bank account information unless you ask us to when you make payment, and you may withdraw your consent to this by notifying us by email to <a href="mailto:info@cablebahamas.com">info@cablebahamas.com</a> or <a href="mailto:customercare@bealiv.com">customercare@bealiv.com</a>.
        </p>
        <p>
          We will not share your PII with a third party outside of the CBL Group except for the purposes set out in or in the limited circumstances disclosed in this Privacy Policy. We do not sell, trade, or except as is disclosed herein otherwise transfer your PII to outside parties.
        </p>
        <p>
          We may also be required by applicable laws or regulations to disclose your PII or content that you have made available to us when using our services, or other activities that you have carried out using any services or device(s) provided to you by us to law enforcement agencies or on receipt of any warrant, subpoena, court order or lawful direction. We may also disclose your PII in order to enforce our policies, or protect our or others’ rights, property, or safety.
        </p>
        <p>
          We are required to release or share your PII and information about your account with a credit bureau or reporting agency and may contact such credit bureau or reporting agency to request or obtain a credit reference for you, and unless you tell us otherwise, you give us the right to share your information for this purpose by accepting these terms and conditions. You may always ask us for a copy of all the information that we have stored for you.
        </p>
        <p>
          Should we be subject to or involved in a merger, acquisition, restructuring, insolvency, or bankruptcy proceedings, or sale of some or all of our assets, we will be required to share your PII with the new owners or successor entities in connection with the transaction in accordance with applicable laws.
        </p>
      </section>
  
      <section>
        <h2>How Do We Keep and Protect Your Information?</h2>
        <p>
          We make reasonable efforts to ensure that the information that we maintain for you is accurate and remains up to date. It is your responsibility to ensure that you give us accurate and true information and that you provide us with updated information in the event of changes.
        </p>
        <p>
          The protection and security of your PII is particularly important to us and we have employed a variety of physical, technical, and administrative security measures designed to safeguard and protect the confidentiality of the PII you provide to us.
        </p>
        <p>
          Your PII is stored behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.
        </p>
        <p>
          Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible. We use regular Malware Scanning.
        </p>
        <p>
          All transactions are processed through a gateway provider and are not stored or processed on our servers. Despite our best efforts, our security measures are not perfect, and our systems are not impenetrable. We cannot guarantee that your use of our website will be protected from all malware, viruses, or security threats. We will only keep your PII for the period necessary to fulfill the purposes disclosed in this Privacy Policy or for as long as may be required or permitted by applicable law.
        </p>
        <p>
          Should a data breach occur we will take either or both of the following responsive actions:
        </p>
        <ul>
          <li>We will notify the users via email within 7 business days and/or we will notify the users via in-site notification within 7 business days.</li>
        </ul>
      </section>
  
      <section>
        <h2>Online Safety and Privacy of Children</h2>
        <p>
          The CBL Group does not intentionally collect PII from or specifically market our products and services to children under 13. Children should always seek the permission of their parents or guardians before visiting any website or disclosing information and parents and guardians should control and monitor internet usage by children.
        </p>
      </section>
  
      <section>
        <h2>Third Party Links</h2>
        <p>
          We do not include or offer third-party products or services on our website.
        </p>
      </section>
  
      <section>
        <h2>How Does Our Site Handle ‘Do Not Track’ Signals?</h2>
        <p>
          We honor ‘do not track’ signals and do not track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.
        </p>
      </section>
  
      <section>
        <h2>Do We Allow Third Party Behavioral Tracking on Our Sites?</h2>
        <p>
          It’s also important to note that we do not allow third-party behavioral tracking.
        </p>
      </section>
  
      <section>
        <h2>We Agree to the Following:</h2>
        <ul>
          <li>We will NOT use false or misleading subjects or email addresses.</li>
          <li>We will identify an advertising message as an advertisement in some reasonable way.</li>
          <li>We will include the physical address of our business or site headquarters.</li>
          <li>We will honor opt-out/unsubscribe requests quickly.</li>
        </ul>
        <p>
          We may contact you by email, SMS, or other instant message or by calling you.
        </p>
        <p>
          You can always choose whether you wish to receive voice, written, or electronic marketing messages from us. If at any time you would like to unsubscribe from receiving future emails or SMS, please notify us via email at <a href="mailto:cbl-info@cablebahamas.com">cbl-info@cablebahamas.com</a> or <a href="mailto:customercare@bealiv.com">customercare@bealiv.com</a> and we will promptly remove you from ALL correspondence. Please note that should you request that we discontinue contacting you in any one of the foregoing ways, you may not receive important information from us regarding the products and services we have provided to you.
        </p>
      </section>
  
      <section>
        <h2>Contacting Us</h2>
        <p>
          If there are any questions regarding this Privacy Policy, you may contact us using the information below.
        </p>
        <address>
          <strong>Cable Bahamas Ltd.</strong><br />
          Robinson Road<br />
          Nassau, The Bahamas<br />
          CB-13050<br />
          Tel. 242-601-2200<br />
          Email: <a href="mailto:cbl-info@cablebahamas.com">cbl-info@cablebahamas.com</a>
        </address>
        <p>OR</p>
        <address>
          <strong>Be Aliv Limited</strong><br />
          CB-12301<br />
          Robinson Road<br />
          Nassau, The Bahamas<br />
          Tel. 242-801-2548<br />
          Email: <a href="mailto:customercare@bealiv.com">customercare@bealiv.com</a>
        </address>
      </section>
  
      <section>
        <h2>Changes and Updates to This Policy</h2>
        <p>
          We may amend or update this policy from time to time. Whenever we do so, we will notify you by posting the changes to this website or as appropriate and will obtain your consent where necessary.
        </p>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PrivacyPolicy',
  };
  </script>
  
  <style scoped>
  .privacy-policy {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  h1, h2 {
    color: #333;
  }
  
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  address {
    font-style: normal;
  }
  </style>
  