<template>
  <div class="hbo-signup-container">
    <!-- Logo Section -->
    <div class="logo-section">
      <img
        src="http://images.ctfassets.net/u7e5kg3vu9je/6jI54yL4OlfH3eDGJRahCF/d55dc81ffbd130788e5ebf81c52945c2/hbo-logo-r-black.png"
        alt="HBO Logo"
        class="logo"
      />
    </div>

    <!-- Form Section -->
    <div class="form-section">
      <div v-if="step === 1 || step === 2" class="form-header">
        <h2>Sign up for HBO Add-on</h2>
        <p>Enjoy premium HBO content through ALIV Fibr or REV!</p>
      </div>

      <form v-if="step === 1 || step === 2" @submit.prevent="handleSubmit">
        <div v-if="step === 1">
          <!-- Name Field -->
          <div class="form-group">
            <label for="name">Name</label>
            <input
              type="text"
              id="name"
              v-model="form.name"
              placeholder="Enter your first and last name"
              required
            />
          </div>

          <!-- Account Number -->
          <div class="form-group">
            <label for="accountNumber">Account Number</label>
            <input
              type="text"
              id="accountNumber"
              v-model="form.accountNumber"
              placeholder="1234-5678-9012"
              required
            />
          </div>

          <!-- Phone Number -->
          <div class="form-group">
            <label for="phone">Phone Number</label>
            <input
              type="tel"
              id="phone"
              v-model="form.phone"
              placeholder="Enter your phone number"
              required
            />
          </div>

          <div class="button-group">
            <button
              type="button"
              class="btn-primary"
              @click="nextStep"
              :disabled="!isStep1Complete"
            >
              Next
            </button>
          </div>
        </div>

        <div v-if="step === 2">
          <!-- Choose Subscription Type -->
          <div class="form-group">
            <label>Choose Subscription Type</label>
            <div
              v-for="(option, index) in subscriptionOptions"
              :key="index"
              :class="[
                'option-button',
                { selected: form.subscriptionType === option },
              ]"
              @click="form.subscriptionType = option"
            >
              {{ option }}
            </div>
          </div>

          <!-- Choose Package -->
          <div class="form-group">
            <label>Choose HBO Package</label>
            <div
              v-for="(option, index) in hboPackages"
              :key="index"
              :class="[
                'option-button',
                { selected: form.hboPackage === option },
              ]"
              @click="form.hboPackage = option"
            >
              {{ option }}
            </div>
          </div>

          <div class="button-group">
            <button type="button" class="btn-secondary" @click="prevStep">
              Back
            </button>
            <button type="submit" class="btn-primary">Submit</button>
          </div>
        </div>
      </form>

      <div v-if="step === 3" class="confirmation-screen">
        <h2>Thank You!</h2>
        <p>
          We’ve received your information and will contact you soon to complete
          the process.
        </p>
        <p>
          If you have any questions, feel free to reach out to our support team.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HBOSignupForm",
  data() {
    return {
      step: 1,
      form: {
        name: "",
        accountNumber: "",
        phone: "",
        subscriptionType: "",
        hboPackage: "",
      },
      subscriptionOptions: ["ALIV Fibr", "REV"],
      hboPackages: ["HBO Basic", "HBO Premium", "HBO Max"],
    };
  },
  computed: {
    isStep1Complete() {
      return (
        this.form.name.trim() !== "" &&
        this.form.accountNumber.trim() !== "" &&
        this.form.phone.trim() !== ""
      );
    },
  },
  methods: {
    nextStep() {
      this.step++;
    },
    prevStep() {
      this.step--;
    },
    async handleSubmit() {
      if (!this.form.subscriptionType || !this.form.hboPackage) {
        alert("Please select all required options.");
        return;
      }
      await axios.post("https://email-server-swart.vercel.app/api/v89/email", {
        subject: "HBO Add-on Signup",
        message: JSON.stringify(this.form),
      });
      this.step = 3; // Move to confirmation screen
    },
  },
};
</script>

<style scoped>
/* General Layout */
.hbo-signup-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2em;
  gap: 2em;
}

.logo-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  max-width: 30rem;
  height: auto;
}

/* Form Styling */
.form-section {
  flex: 2;
  background: #fff;
  border-radius: 10px;
  padding: 2em;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.form-header h2 {
  font-size: 1.8em;
  color: #635890; /* Updated color */
  margin-bottom: 0.5em;
}

.form-header p {
  font-size: 1em;
  color: #666;
  margin-bottom: 1.5em;
}

.form-group {
  margin-bottom: 1.5em;
}

label {
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5em;
  display: block;
}

input {
  width: 100%;
  padding: 0.8em;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  font-size: 1em;
  transition: all 0.3s ease;
}

input:focus {
  border-color: #635890; /* Updated color */
}

.option-button {
  width: calc(100% - 2em);
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 1em;
  text-align: center;
  cursor: pointer;
  transition:
    border-color 0.3s ease,
    background-color 0.3s ease;
}

.option-button:hover {
  border-color: #635890; /* Updated color */
}

.option-button.selected {
  border-color: #635890; /* Updated color */
  background-color: #f0f0f0;
  font-weight: bold;
}

button {
  padding: 0.8em 1.5em;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #635890; /* Updated color */
  color: white;
}

.btn-primary:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.btn-primary:hover:not(:disabled) {
  background-color: #51467a; /* Updated hover color */
}

.btn-secondary {
  background-color: #ccc;
  color: #333;
}

.btn-secondary:hover {
  background-color: #bbb;
}

.button-group {
  display: flex;
  justify-content: space-between;
  gap: 1em;
}

/* Confirmation Screen */
.confirmation-screen {
  text-align: center;
  color: #635890; /* Updated color */
}

.confirmation-screen h2 {
  font-size: 2em;
}

.confirmation-screen p {
  font-size: 1.2em;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hbo-signup-container {
    flex-direction: column;
    align-items: center;
  }

  .logo-section {
    margin-bottom: 1.5em;
  }

  .logo {
    max-width: 25rem;
  }
}
</style>
