<template>
  <div class="about-fibr">
    <div class="sub-heading">{{ fields.name }}</div>
    <div class="heading-dark">{{ fields.heading }}</div>
    <div class="row">
      <div v-for="section in aboutSections" :key="section" class="col-md-4">
        <AboutFibrCard :fields="section.fields"> </AboutFibrCard>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import entryId from "@/config/entryid.json";
import AboutFibrCard from "./AboutFibrCard.vue";

export default defineComponent({
  name: "AboutFibr",
  inject: ["$contentful"],
  components: {
    AboutFibrCard,
  },
  data() {
    return {
      fields: {
        heading: "",
        copy: "",
      },
      aboutSections: [],
    };
  },
  created() {
    this.$contentful
      .getEntry(entryId.fibrHome.aboutFibr.inAboutFibr)
      .then((val) => {
        this.fields = val.fields;
        this.aboutSections = this.fields.aboutFIBR;
        if (this.aboutSections.length > 3) {
          this.aboutSections.pop();
        }
      });
  },
});
</script>

<style scoped lang="scss">
.about-fibr {
  position: relative;
  padding-top: $small-spacing;
  padding-bottom: $small-spacing;

  &:after {
    @include full-window-width;
    content: "";
    background-color: #fafafa;
    z-index: -1;
  }
}

.sub-heading {
  margin-bottom: 8px;
}

.heading-dark {
  margin-bottom: 33px;
}

.link {
  font-style: normal;
  font-weight: 700;
  font-size: $font-xs;
  line-height: 18px;
  text-align: center;
  text-decoration-line: underline;
  color: #9b51e0;
  cursor: pointer;
  margin-bottom: 40px;
}
</style>
