<template>
  <div>
    <div v-if="smallTitle">
      <div class="row">
        <div class="col-sm-4">
          <div
            class="qr-code div-icon-image"
            :style="{ backgroundImage: `url(${appQrCode})` }"
          ></div>
        </div>
        <div class="button-div-small-title col-sm-8">
          <div class="download-copy">{{ fields.downloadCopy }}</div>
          <div class="row">
            <div class="col-sm-6">
              <a :href="appStore.link">
                <div
                  class="button-small-title hover-effect-noshadow div-icon-image"
                  :style="{ backgroundImage: `url(${appStore.imageUrl})` }"
                ></div>
              </a>
            </div>
            <div class="col-sm-6">
              <a :href="playStore.link">
                <div
                  class="button-small-title hover-effect-noshadow div-icon-image"
                  :style="{ backgroundImage: `url(${playStore.imageUrl})` }"
                ></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="banner-title">{{ title }}</div>
      <div class="button-div">
        <div class="row">
          <div class="col-sm-4">
            <div
              class="qr-code div-icon-image"
              :style="{ backgroundImage: `url(${appQrCode})` }"
            ></div>
          </div>
          <div class="col-sm-4">
            <a :href="appStore.link">
              <div
                class="button hover-effect-noshadow div-icon-image"
                :style="{ backgroundImage: `url(${appStore.imageUrl})` }"
              ></div>
            </a>
          </div>
          <div class="col-sm-4">
            <a :href="playStore.link">
              <div
                class="button hover-effect-noshadow div-icon-image"
                :style="{ backgroundImage: `url(${playStore.imageUrl})` }"
              ></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FibrPortal",
  inject: ["$contentful"],
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
    smallTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: "",
      appStore: {
        imageUrl: "",
        link: "",
      },
      playStore: {
        imageUrl: "",
        link: "",
      },
      appQrCode: {},
    };
  },
  watch: {
    fields: {
      handler() {
        this.title = this.fields?.title;
        this.appStore.imageUrl = this.fields?.appStoreImage?.fields?.file?.url;
        this.appStore.link = this.fields?.appStoreLink;
        this.playStore.imageUrl = this.fields?.image?.fields?.file?.url;
        this.playStore.link = this.fields?.googlePlayStoreLink;
        this.appQrCode = this.fields?.appQrCode?.fields?.file?.url;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.banner-title {
  color: #000;
  text-align: center;
  font-size: $font-l;
  font-style: normal;
  font-weight: 700;
}

.button-div {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  margin-top: 20px;

  &-small-title {
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
  }
}

.button {
  min-width: 120px;
  height: 140px;

  &-small-title {
    min-width: 120px;
    height: 50px;
  }
}

.qr-code {
  min-width: 140px;
  height: 140px;
}

.download-copy {
  font-size: $font-s;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
