<template>
  <div class="product-container">
    <div class="sub-heading">{{ fields.heading }}</div>
    <div class="heading">{{ fields.subHeading }}</div>
    <div class="row">
      <div class="col-md-4">
        <FibrHeroProductCard
          :entryId="internetEntryId"
          :routeName="`fibr-internet`"
          :linkText="`View internet products`"
        >
        </FibrHeroProductCard>
      </div>
      <div class="col-md-4">
        <FibrHeroProductCard
          :entryId="phoneEntryId"
          :routeName="`fibr-phone`"
          :linkText="`View phone products`"
        >
        </FibrHeroProductCard>
      </div>
      <div class="col-md-4">
        <FibrHeroProductCard
          :entryId="tvEntryId"
          :routeName="`fibr-tv`"
          :linkText="`View TV products`"
        >
        </FibrHeroProductCard>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import FibrHeroProductCard from "./FibrHeroProductCard.vue";
import entryId from "@/config/entryid.json";
import router from "@/router";

export default defineComponent({
  name: "FibrHeroProductContainer",
  inject: ["$contentful"],
  components: {
    FibrHeroProductCard,
  },
  data() {
    return {
      fields: {
        heading: "",
        copy: "",
      },
      heroBackground: "",
      internetEntryId: entryId.fibrHome.hero.fibrHeroInternet,
      phoneEntryId: entryId.fibrHome.hero.fibrHeroPhone,
      tvEntryId: entryId.fibrHome.hero.fibrHeroTV,
    };
  },
  created() {
    this.$contentful.getEntry(entryId.fibrHome.hero.fibrHero).then((val) => {
      this.fields = val.fields;
    });
  },
  methods: {
    bannerClicked() {
      router.push({ name: "fibr-products" });
    },
  },
});
</script>

<style scoped lang="scss">
.product-container {
  position: relative;
  padding-top: $small-spacing;
  padding-bottom: $small-spacing;

  &:after {
    @include full-window-width;
    content: "";
    background-color: #fafafa;
    z-index: -1;
  }
}

.sub-heading {
  margin-bottom: 6px;
}

.heading {
  font-size: $font-l;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 20px;
}
</style>
