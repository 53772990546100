<template>
  <FibrProductBanner :imageUrl="imageUrl"></FibrProductBanner>
  <div class="horizontal-page-margin vertical-page-margin">
    <div class="section-margin">
      <div class="sub-heading">{{ subheading }}</div>
      <div class="title">{{ title }}</div>
      <div class="description">{{ description }}</div>
    </div>
    <div class="section-margin-small">
      <div class="row">
        <div class="col-md-6">
          <FibrProductCard
            :fields="internetFields"
            :min-height="minHeight"
            :routeName="`fibr-internet`"
          >
          </FibrProductCard>
        </div>
        <div class="col-md-6">
          <FibrProductCard
            :fields="phoneFields"
            :min-height="minHeight"
            :routeName="`fibr-phone`"
          >
          </FibrProductCard>
        </div>
        <div class="col-md-6">
          <FibrProductCard
            :fields="tvFields"
            :min-height="minHeight"
            :routeName="`fibr-tv`"
          >
          </FibrProductCard>
        </div>
        <div class="col-md-6">
          <FibrProductCard
            :fields="bundlesFields"
            :min-height="minHeight"
            :routeName="`fibr-bundles`"
          >
          </FibrProductCard>
        </div>
      </div>
    </div>
    <ImageWithText
      class="section-margin"
      :imageUrl="bundlesImage"
      :leftAlignImage="false"
    >
      <div>
        <div class="sub-heading bundles-info">{{ bundlesInfo }}</div>
        <div class="bundles-title">{{ bundlesTitle }}</div>
        <RichText
          class="bundles-description"
          :document="bundlesDescription"
        ></RichText>
      </div>
    </ImageWithText>
    <CheckAliv class="section-margin"></CheckAliv>
  </div>
</template>

<script>
import FibrProductBanner from "@/components/common/FibrProductBanner.vue";
import FibrProductCard from "@/components/fibr-products/FibrProductCard.vue";
import ImageWithText from "@/components/common/ImageWithText.vue";
import entryId from "@/config/entryid.json";
import RichText from "@/components/common/RichText.vue";
import CheckAliv from "@/components/common/CheckAliv.vue";

export default {
  name: "FibrProducts",
  inject: ["$contentful"],
  components: {
    FibrProductBanner,
    FibrProductCard,
    ImageWithText,
    RichText,
    CheckAliv,
  },
  data() {
    return {
      subheading: "Products",
      title: "",
      description: "",
      minHeight: "316px",
      imageUrl: "",
      internetFields: {},
      phoneFields: {},
      tvFields: {},
      bundlesFields: {},
      bundlesImage: "",
      bundlesInfo: "Featured Bundle",
      bundlesTitle: "",
      bundlesDescription: {},
    };
  },
  created() {
    this.$contentful
      .getEntry(entryId.fibrProducts.products.fibrProductsHeaderImage)
      .then((val) => {
        this.imageUrl = val.fields.backgroundImage.fields.file.url;
      });
    this.$contentful
      .getEntry(
        entryId.fibrProducts.products.fibrProductsAndServices
          .sectionProductAndServiceFibrProducts
      )
      .then((val) => {
        this.title = val.fields.title;
        this.description = val.fields.description;
        const productContent = val.fields.productContent;
        this.internetFields = productContent[0].fields;
        this.phoneFields = productContent[1].fields;
        this.tvFields = productContent[2].fields;
        this.bundlesFields = productContent[3].fields;
      });
    this.$contentful
      .getEntry(entryId.fibrProducts.products.fibrBundleDeal)
      .then((val) => {
        this.bundlesImage = val.fields.image.fields.file.url;
        this.bundlesTitle = val.fields.title;
        this.bundlesDescription = val.fields.descriptionRt;
      });
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: $font-l;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: center;
}

.description {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  font-size: $font-s;
  font-weight: 450;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #4f4f4f;
}

.bundles-info {
  text-align: left;
}

.sub-heading {
  margin-bottom: 5px;
}

.bundles-title {
  font-size: $font-l;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0em;
}

.bundles-description {
  color: #4f4f4f;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  margin-top: 24px;
}
</style>
