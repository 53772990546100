<template>
    <div class="terms-conditions">
      <h1>ALIV Fibr Specific Terms and Conditions: TV</h1>
  
      <section>
        <p>
          These terms and conditions are divided into a general section that applies to every service, and then into service-specific terms and conditions. Please read the GENERAL TERMS AND CONDITIONS and each section that applies to your service because all of the relevant terms will apply to you.
        </p>
      </section>
  
      <section>
        <h2>1. Personal use:</h2>
        <ul>
          <li>
            This service is only for your use and the use of the people that you authorise to use the service.
          </li>
          <li>
            The services sold to you under this contract are for residential purposes only.
          </li>
        </ul>
      </section>
  
      <section>
        <h2>2. Device(s) ownership and return:</h2>
        <ul>
          <li>
            In order to provide you with television services, we will make a device(s) available to you for rental for the duration of the contract.
          </li>
          <li>
            This device(s) will remain the property of Cable Bahamas and on termination of this contract for any reason, the device(s) must be returned to Cable Bahamas in workable condition.
          </li>
        </ul>
      </section>
  
      <section>
        <h2>3. Terms and conditions of use of the device(s):</h2>
        <ul>
          <li>
            All of the terms and conditions set out in clause 3 of the GENERAL TERMS AND CONDITIONS apply to the device(s) referred to in this section.
          </li>
          <li>
            We will require you to pay a security deposit in order to take delivery of the device(s) and prior to installation.
          </li>
          <li>
            The device(s) may only be used to receive the signal distributed to the address indicated by you in your order, which may not be an address for a commercial business including a business that provides accommodation to third parties, or a multi-dwelling unit or complex with multiple rooms.
          </li>
        </ul>
      </section>
  
      <section>
        <h2>4. Terms and conditions of use of the services:</h2>
        <ul>
          <li>
            It is forbidden to use the services in any way that may endanger children, or to use the services or allow the services to be used to expose them to disturbing and harmful materials including adult experiences. We recommend that you do not allow your children to use cable TV services unsupervised.
          </li>
        </ul>
      </section>
  
      <section>
        <h2>5. Blackouts</h2>
        <p>
          Blackouts are controlled by various leagues, associations, and networks that maintain the rights to broadcast certain content and we must adhere to these restrictions to protect the agreements that enable us to deliver our existing channel line-up. We will be required by Vendors to blackout certain events. We are not responsible for any refunds due as a result of a blackout. We may attempt to insert programming. However, this may not always be possible.
        </p>
      </section>
  
      <section>
        <h2>6. Terms of supply of television services:</h2>
        <p>
          We may apply additional terms to service packages that you choose from time to time, which terms may result in a change from the programmes included in a service package, or the prices for additional programmes that may be added to the package from time to time.
        </p>
      </section>
  
      <section>
        <h2>7. Charges:</h2>
        <ul>
          <li>
            You are required to make payment of the security deposit and any charges associated with the installation and the first month’s charges in advance, prior to installation.
          </li>
          <li>
            We will invoice you at the time you place your order with us. Thereafter you will be responsible for payment of the monthly charges.
          </li>
          <li>
            We may charge you for the use of premium services for a specific time period that you use the premium services.
          </li>
        </ul>
        <p>Revised: September 2022</p>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SpecificTermsTVServices',
  };
  </script>
  
  <style scoped>
  .terms-conditions {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  h1 {
    color: #635890;
  }
  
  h2 {
    color: #635890;
    margin-top: 20px;
  }
  
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  a {
    color: #635890;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  p {
    color: #666;
  }
  </style>
  