<template>
  <div>
    <div class="bundle-card hover-effect" @click="cardClicked">
      <div class="card-section-top">
        <div class="bundle-name">{{ bundle?.bundleName }}</div>
        <div class="price-block">
          <span class="price">{{ bundle?.price?.price }}</span>
          <span class="vat">{{ bundle?.price?.vat }}</span>
        </div>
        <RichText
          class="description"
          :document="bundle?.price?.description"
        ></RichText>
      </div>
      <div class="card-section-bottom" v-if="benefits && benefits?.length > 0">
        <div class="bundle-copy" v-if="showBundleCopy">{{ bundleCopy }}</div>
        <div class="benefits" v-for="(benefit, index) in benefits" :key="index">
          <span
            class="div-icon-image icon"
            :style="{
              backgroundImage:
                'url(' + require('@/assets/icons/green_check.svg') + ')',
            }"
          ></span>
          <span class="benefit">{{ benefit }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RichText from "../common/RichText.vue";
import router from "@/router";

export default {
  name: "BundleDetailsCard",
  inject: ["$contentful"],
  components: { RichText },
  props: {
    bundle: {
      type: Object,
      default: () => {},
    },
    showBundleCopy: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      bundleCopy: "Includes everything above plus:",
    };
  },
  computed: {
    benefits() {
      return this.bundle?.bundleDetails?.find(
        (x) => x.categoryName === "ALIV Fibr Broadband"
      )?.benefits;
    },
  },
  methods: {
    cardClicked() {
      router.push({
        name: "bundle-details",
        params: { bundle: encodeURI(this.bundle.bundleName) },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.bundle-card {
  border-radius: $border-radius;
  border: 1px solid #635890;
  background: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  height: 100%;
  cursor: pointer;
}

.card-section {
  &-top {
    padding: 20px 15px 5px 15px;
  }

  &-bottom {
    border-top: 1px solid #e0e0e0;
    padding: 20px 15px;
  }
}

.bundle-name {
  color: #000;
  font-size: $font-s;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.price-block {
  margin-top: 10px;
}

.price {
  color: #635890;
  font-size: $font-l;
  font-style: normal;
  font-weight: 700;
  line-height: $font-l;
}

.vat {
  color: #635890;
  font-size: $font-s;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 5px;
}

.description {
  color: #4f4f4f;
  font-size: $font-xxs;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  margin-top: 8px;
}

.bundle-copy {
  color: #333;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px;
}

.benefits {
  white-space: nowrap;
  margin-bottom: 10px;
}

.icon {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  vertical-align: top;
}

.benefit {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  color: black;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}
</style>
