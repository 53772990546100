<template>
  <div class="product-card hover-effect" @click="cardClicked">
    <div>
      <div
        class="background div-background-image"
        :style="{ backgroundImage: `url(${imageUrl})` }"
      ></div>
      <div class="text-container">
        <div>
          <div
            class="icon div-icon-image"
            :style="{ backgroundImage: `url(${iconUrl})` }"
          ></div>
          <div class="heading">{{ fields.heading }}</div>
        </div>
        <div class="description">{{ fields.copy }}</div>
        <div class="link">{{ linkText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import router from "@/router";

export default defineComponent({
  name: "FibrHeroProductCard",
  inject: ["$contentful"],
  props: {
    entryId: {
      type: String,
      default: "",
    },
    routeName: {
      type: String,
      default: "",
    },
    linkText: {
      type: String,
      default: "asd",
    },
  },
  data() {
    return {
      fields: {
        heading: "",
        copy: "",
      },
      iconUrl: "",
      imageUrl: "",
    };
  },
  created() {
    this.$contentful.getEntry(this.entryId).then((val) => {
      this.fields = val.fields;
      this.iconUrl = val.fields?.media?.fields?.file?.url;
      this.imageUrl = val.fields?.image?.fields?.file?.url;
    });
  },
  methods: {
    cardClicked(e) {
      e.stopPropagation();
      router.push({ name: this.routeName });
    },
  },
});
</script>

<style scoped lang="scss">
.text-container {
  padding: 32px;
}

.heading {
  font-style: normal;
  font-weight: 700;
  font-size: $font-s;
  line-height: 32px;
  color: #000000;
  white-space: nowrap;
  display: inline-block;
  margin-left: 10px;
}

.description {
  font-style: normal;
  font-weight: 450;
  font-size: $font-xs;
  line-height: 20px;
  color: #4f4f4f;
  margin-top: 12px;
  min-height: 64px;
}

.link {
  font-size: $font-xs;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #635890;
  text-decoration: underline;
  margin-top: 12px;
}

.background {
  height: 204px;
  width: 100%;
}

.icon {
  position: relative;
  height: 20px;
  width: 20px;
  top: 3px;
  display: inline-block;
}

.product-card {
  min-height: 404px;
  height: 100%;
  position: relative;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: $border-radius;
}
</style>
