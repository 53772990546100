<template>
    <div class="terms-conditions">
      <h1>ALIV Fibr Acceptable Use Policy for Broadband Internet</h1>
  
      <section>
        <h2>This policy and you</h2>
        <ul>
          <li>
            We want you and other customers of broadband services to enjoy these services to the full. This means that your use of CBL’s Broadband services should respect the rights of others, and here we set out a few rules for you to follow. We also explain what we may do if you don’t follow them.
          </li>
          <li>
            By using our services, you agree to follow these rules. We may update them from time to time, and we’ll give you one month’s notice of any changes. If you don’t agree to this policy or the changes, you must let us know and stop using our services.
          </li>
          <li>
            This policy also applies to people using your equipment, services and broadband connection, and you will be responsible for ensuring that they follow this policy. You are also responsible for all content that you use or put online and for the materials and data on your devices, as well as for your own websites and your pages on other peoples’ websites. We do not take any responsibility for such content.
          </li>
        </ul>
      </section>
  
      <section>
        <h2>Act legally</h2>
        <p>While using our services, you’ll want to do things – organise activities, order goods, write emails, send videos and create content. What you do must comply with the laws of The Bahamas. For example, you must not:</p>
        <ul>
          <li>carry out, promote or assist illegal activities, such as terrorism, fraud, pyramid schemes, human trafficking or child pornography</li>
          <li>promote or threaten violence towards anyone</li>
          <li>host, post, transmit or disseminate any unlawful material or any material considered harmful to minors or vulnerable people</li>
          <li>abuse or harass anyone, for example by making offensive, libellous, obscene, misleading or menacing comments</li>
          <li>encourage hatred, for example by making racist, sexist, discriminatory or ethnically offensive comments</li>
          <li>infringe other people’s or organisations’ copyright or intellectual property.</li>
        </ul>
      </section>
  
      <section>
        <h2>Keep secure</h2>
        <p>
          You’ll want to keep the equipment you use for our services secure and safe, and you should help other people do the same. So you must:
        </p>
        <ul>
          <li>ensure your devices have the right firewall, anti-virus protection and appropriate password protection</li>
          <li>keep your passwords secure, including those used to access our services</li>
          <li>prevent unauthorised access to your equipment or network, or to our services</li>
          <li>not hack, send viruses, spyware, malware or corrupted files to other people’s (including our) devices, servers or networks</li>
          <li>not use or distribute tools that compromise the security of other people’s equipment, systems or networks</li>
          <li>prevent your devices from being used to monitor, overload, interfere with, gain unauthorised access to, or deny service on other people’s accounts, websites, applications, servers or networks</li>
          <li>not impersonate someone else, or misrepresent who you are (like using fake email headers, signatures, sender names or address, or caller identification details)</li>
          <li>not falsify user or transmission information, such as email headers, Internet Protocol addresses, or contact information</li>
          <li>not claim to represent us in communications with others</li>
          <li>comply with the license conditions applying to any software which comes with our services.</li>
        </ul>
      </section>
  
      <section>
        <h2>Protect other people’s privacy</h2>
        <ul>
          <li>send unsolicited communications to people or groups without their agreement (such as spam, mailbombs, chain letters and nuisance calls)</li>
          <li>intentionally distress, offend or worry anyone (through things like hoax calls or upsetting emails)</li>
          <li>use our services contrary to the generally accepted moral standards in The Bahamas</li>
          <li>intercept other people’s data or obtain information from them fraudulently</li>
          <li>behave deliberately in a way that might harm children or other vulnerable people</li>
          <li>interfere with people’s rights to privacy and their legal enjoyment of broadband services.</li>
        </ul>
      </section>
  
      <section>
        <h2>Share our network fairly</h2>
        <p>
          Like many Internet providers, we may have to manage our network so that no application, service or user consumes more than their fair share of bandwidth and thereby degrades the enjoyment of other users, especially at peak times (typically 6:00 pm to 12 midnight), when customers may experience extended download or upload times. So:
        </p>
        <ul>
          <li>we ask you to use the internet reasonably. If we think that your usage of the internet is excessive (which we consider to be 5x the average consumption of our residential users over a given period of time. Which will be reviewed periodically), we may contact you, and ask you to reduce your usage. If you don’t, we may then carry out some or all of the steps listed in paragraph 8 below.</li>
          <li>you must not restrict or degrade our ability to deliver and monitor our services</li>
          <li>you must not use our residential services for commercial purposes, unless you are a sole trader or are working from home temporarily</li>
          <li>unless you have our agreement, you must not distribute or resell our services, run a server or provide network services to other people.</li>
        </ul>
      </section>
  
      <section>
        <h2>Making this policy work</h2>
        <ul>
          <li>look into the breach</li>
          <li>monitor your bandwidth, usage or content</li>
          <li>contact you to discuss the apparent breach (using your personal details to make contact with you)</li>
          <li>issue you with a formal warning</li>
          <li>restrict your access to any material or data, including websites and email accounts</li>
          <li>remove any unacceptable content, or suspend or delete your web pages</li>
          <li>block, limit or suspend your access to or consumption of any or all of our services</li>
          <li>terminate your agreement with us and your access to our services (with or without notice)</li>
          <li>recover from you the costs that we have reasonably incurred as a result of the violation</li>
          <li>take any other legal or technical action that we deem appropriate.</li>
        </ul>
        <p>If you agree in writing to stop committing the breach, we may lift the restrictions or restore your service.</p>
      </section>
  
      <section>
        <h2>Reporting and cooperation with law enforcement</h2>
        <ul>
          <li>
            In serious cases, we may report you to the police or another law enforcement agency, and give them your personal information. Sometimes, we might be asked by public bodies such as law enforcement agencies to give information about you. If those requests are lawful, we obey them. We may also cooperate with other internet, computer facilities or network providers to enforce this policy and their policies.
          </li>
          <li>
            If you think that someone else has breached this policy and you want to report them to us, you should get in touch with us at: <a href="mailto:info@alivfibr.com">info@alivfibr.com</a>
          </li>
          <li>
            If you want to understand more about any of the points in this policy, please do contact us at: <a href="mailto:info@alivfibr.com">info@alivfibr.com</a>
          </li>
        </ul>
        <p>September 2022</p>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AcceptableUsePolicyBroadband',
  };
  </script>
  
  <style scoped>
  .terms-conditions {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  h1 {
    color: #635890;
  }
  
  h2 {
    color: #635890;
    margin-top: 20px;
  }
  
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  a {
    color: #635890;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  p {
    color: #666;
  }
  </style>
  