<template>
  <div>
    <h3></h3>
    <div class="map-container">
      <SupportForm />
    </div>
  </div>
</template>

<script>
import SupportForm from "@/components/common/SupportForm.vue";

export default {
  name: "AddonSignup",
  components: {
    SupportForm,
  },
};
</script>

<style scoped>
h3 {
  height: 6rem;
}

.map-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  margin-top: 5rem;
  max-height: 75vh; /* Limit maximum height */
}

/* Access denied message styles remain unchanged */
</style>
