<template>
  <div v-if="content" class="terms-conditions">
    <div class="my-5" />
    <RichText :document="content" />
  </div>
  <p v-else>Loading...</p>
</template>

<script>
import { createClient } from "@/plugins/contentful.js";
import RichText from "@/components/common/RichText.vue";

export default {
  data() {
    return {
      content: null,
    };
  },
  components: {
    RichText,
  },
  async created() {
    const entryId = "4MVRTnvwVfVDujWHAeM8vn"; // Specific Contentful entry ID
    const client = createClient();

    try {
      const response = await client.getEntry(entryId); // Fetching by entry ID
      console.log("Found entry:", response.fields);
      this.content = response.fields.pageContent; // Assuming `pageContent` is the rich text field
    } catch (error) {
      console.error("Error fetching data from Contentful:", error);
    }
  },
};
</script>

<style scoped>
.terms-conditions {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

a {
  color: #635890;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
